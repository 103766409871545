import { ProviderClass } from '../interfaces/ProviderClass';
import MarkerClusterer, { MarkerClustererOptions } from '@googlemaps/markerclustererplus';
import { ResultsGeoJson, FeaturesGeoJson, PropertiesGeoJson, ResponseGeneral } from '../interfaces/Responses';
import OnTheMap from './onthemap';
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';
import { StyleProcessResults } from '../interfaces/OnTheMap';

/**
 * @classdesc This is the provider for Google Map. It provides all methods for Google Maps functionality
 * @access private
 * @implements ProviderClass
 * @class GoogleMap
 */
export default class GoogleMap implements ProviderClass {
  /**
   * Marker clusterer object {@link MarkerClusterer}
   */
  private _cluster: MarkerClusterer = null;

  /**
   * OverlappingMarkerSpiderfier object
   */
  private _oms: OverlappingMarkerSpiderfier;
  /**
   * Array of google.maps.Marker with property otm_id used to get the marker when we want
   * to open a specific infoWindow
   */
  private _markers: Array<google.maps.Marker & { otm_id?: string }> = [];
  private _polygons: Array<google.maps.Polygon & { otm_id?: string }> = [];

  /**
   * Array of google.maps.Marker (used to manage the status of markers in case of coincident points)
   */
  private _coincidentPointMarkers: Array<google.maps.Marker & { otm_id?: string }> = [];

  /**
   * google maps created
   * @type {google.maps.Map}
   */
  private _gmap: google.maps.Map;
  /**
   * Options with properties used in functionality
   * For details see {@link OnTheMap} class
   */
  private _otm: OnTheMap;

  /**
   * custom event for trigger infoWindowDomReady
   */
  private _infoDomReadyEvent: CustomEvent;

  /**
   * Marker attualmente nello stato "active"
   */
  private currentActiveMarker: google.maps.Marker;
  private currentActivePolygon: google.maps.Polygon;

  private fromOpenInfoWindow: boolean = false;

  constructor(otm: OnTheMap) {
    this._otm = otm;
  }

  /**
   * search for distance matrix with splitted calls
   * @access private
   * @param {ResponseGeneral} data
   * @param {google.maps.LatLngLiteral} originPosition
   * @param {Function} callback
   */
  distanceMatrixSearch(data: ResponseGeneral, originPosition: google.maps.LatLngLiteral, callback?: Function): void {
    let returnCallback: ResponseGeneral = Object.assign(data);
    const features: FeaturesGeoJson[] = (data.data?.results as ResultsGeoJson)?.features as FeaturesGeoJson[];
    let request: google.maps.DistanceMatrixRequest;
    const origins: google.maps.LatLng[] = [new google.maps.LatLng(originPosition.lat, originPosition.lng)];
    const dmService = new google.maps.DistanceMatrixService();

    /* if we have too much features, we split it in some calls, to do that we create Promise foreach chunk
     * and use Promise.all to have all results
     */

    if (features.length >= 25) {
      // array of promise
      const promiseArray: Promise<any>[] = new Array();
      // copy of features because after we will need original features
      const featCopy: FeaturesGeoJson[] = [...features];
      // split the features for create single promise
      while (featCopy.length > 0) {
        // split features in arrays of max 25 items
        const chunkFeaturesArray: FeaturesGeoJson[] = featCopy.splice(0, 25);
        // create the request object
        request = this.getRequestForDistancematrix(chunkFeaturesArray, origins);
        // create a single promise for features chunk
        const singlePromise: Promise<any> = new Promise((resolve: Function, reject: Function) => {
          dmService.getDistanceMatrix(request, (response: google.maps.DistanceMatrixResponse, status: google.maps.DistanceMatrixStatus) => {
            if (status !== 'OK') {
              reject(response);
            } else {
              resolve(response);
            }
          });
        });
        // create array of promise for use it in Promise.all
        promiseArray.push(singlePromise);
      }
      // check if promiseArray is not empty
      if (promiseArray.length) {
        // cal promise all
        Promise.all(promiseArray)
          .then((resultPromise: Array<google.maps.DistanceMatrixResponse>) => {
            // map all distance object (DistanceMatrixResponseElement) in single array
            const resultDistance: Array<google.maps.DistanceMatrixResponseElement> = resultPromise
              .map((resultPromiseItem: google.maps.DistanceMatrixResponse) => {
                return resultPromiseItem.rows[0].elements;
              })
              .flat();

            // add the distance result in each feature properties
            features.map((feat: FeaturesGeoJson, i: number) => {
              const distanceItem: google.maps.DistanceMatrixResponseElement = resultDistance[i];
              feat.properties._otm_distance_value = distanceItem.distance.value;
              feat.properties._otm_distance_value = distanceItem.distance.value;
              feat.properties._otm_distance_text = distanceItem.distance.text;
              feat.properties._otm_duration_value = distanceItem.duration.value;
              feat.properties._otm_duration_text = distanceItem.duration.text;
            });
            // return the features with distance properties
            (returnCallback.data.results as ResultsGeoJson).features = this.orderingDistanceMatrixResults(features);
            this._otm.featureCollection = (returnCallback.data.results as ResultsGeoJson).features;

            if (callback && typeof callback == 'function') {
              callback(returnCallback);
            }
          })
          .catch((reason) => {
            if (callback && typeof callback == 'function') {
              (returnCallback.data.results as ResultsGeoJson).features = [];
              returnCallback.status = 'KO';
              returnCallback.error_message = reason;
              callback(returnCallback);
            }
          });
      } else {
        // promiseArray is empty so there is an error somewhere
        console.error('Error in distance matrix Promise.all');
        if (callback && typeof callback == 'function') {
          (returnCallback.data.results as ResultsGeoJson).features = [];
          returnCallback.status = 'KO';
          returnCallback.error_message = 'Error in distance matrix call';
          callback(returnCallback);
        }
      }
    } else {
      // get the request
      request = this.getRequestForDistancematrix(features, origins);

      // cll the distance matrix
      dmService.getDistanceMatrix(request, (response: google.maps.DistanceMatrixResponse, status: google.maps.DistanceMatrixStatus) => {
        if (status !== 'OK') {
          console.error('Distance Matrix Error');
          if (callback && typeof callback == 'function') {
            (returnCallback.data.results as ResultsGeoJson).features = [];
            returnCallback.status = 'KO';
            returnCallback.error_message = response['status'];
            callback(returnCallback);
          }
        } else {
          try {
            // add the distance result in each feature properties
            const distances = response.rows[0].elements;
            distances.forEach((dItem: google.maps.DistanceMatrixResponseElement, i: number) => {
              const props: PropertiesGeoJson = (data.data.results as ResultsGeoJson).features[i].properties;
              props._otm_distance_value = dItem.distance.value;
              props._otm_distance_text = dItem.distance.text;
              props._otm_duration_value = dItem.duration.value;
              props._otm_duration_text = dItem.duration.text;
            });

            (returnCallback.data.results as ResultsGeoJson).features = this.orderingDistanceMatrixResults(
              (data.data.results as ResultsGeoJson).features as FeaturesGeoJson[]
            );
            this._otm.featureCollection = (returnCallback.data.results as ResultsGeoJson).features;

            if (callback && typeof callback == 'function') {
              callback(returnCallback);
            }
          } catch (e) {
            console.error(e);
            if (callback && typeof callback == 'function') {
              callback(returnCallback);
            }
          }
        }
      });
    }
  }

  /**
   * Order the feature array results of distance matrix
   * @access private
   * @param {FeaturesGeoJson[]} features feature results array not ordined
   * @returns {FeaturesGeoJson[]} ordered Features array
   */
  orderingDistanceMatrixResults(features: FeaturesGeoJson[]): FeaturesGeoJson[] {
    const orderedFeat: FeaturesGeoJson[] = features.sort((a: FeaturesGeoJson, b: FeaturesGeoJson) => {
      return a.properties._otm_distance_value > b.properties._otm_distance_value
        ? 1
        : b.properties._otm_distance_value > a.properties._otm_distance_value
        ? -1
        : 0;
    });
    return orderedFeat;
  }

  /**
   * create the request object for distance matrix service [see]{@link google.maps.DistanceMatrixRequest}
   * @access private
   * @param {FeaturesGeoJson[]} features - the features arrays
   * @param {google.maps.LatLng[]} origins - the lat lng of origin
   * @returns {google.maps.DistanceMatrixRequest} request
   */
  getRequestForDistancematrix(features: FeaturesGeoJson[], origins: google.maps.LatLng[]): google.maps.DistanceMatrixRequest {
    const travelMode = google.maps.TravelMode.DRIVING;
    const destinations: google.maps.LatLng[] = [];

    features.forEach((feat: FeaturesGeoJson) => {
      const latlng: google.maps.LatLng = new google.maps.LatLng(feat.geometry?.coordinates[1], feat.geometry?.coordinates[0]);
      destinations.push(latlng);
    });

    const request: google.maps.DistanceMatrixRequest = { origins, destinations, travelMode };

    if (!request['travelMode']) {
      request['travelMode'] = google.maps.TravelMode.DRIVING;
    }

    return request;
  }

  /**
   * setup the google map
   * @access private
   * @param {google.maps.Map} map
   * @param {boolean} hideOnTheMapLogo
   */
  setupMap(map: google.maps.Map, hideOnTheMapLogo: boolean): void {
    if(!hideOnTheMapLogo) {
      const otmLogo = document.createElement('div');
      otmLogo.setAttribute('class', 'otmlogo');
      const otm_a = document.createElement('a');
      const otm_url = document.createTextNode('OnTheMap');

      otm_a.title = 'OnTheMap';
      otm_a.href = 'https://onthemap.io/';
      otm_a.target = '_blank';
      otm_a.style.color = '#000';

      /*
      otmLogo.setAttribute(
        'style',
        'height = "14px"; lineHeight = "14px";paddingLeft = "6px"; paddingRight = "6px"; marginRight = "1px"; fontSize = "10px"; background = "#ffffff"; opacity = "0.7";'
      );
      */

      otmLogo.appendChild(otm_a);
      otm_a.appendChild(otm_url);

      map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(otmLogo);

      otmLogo.setAttribute('style', 'background: #ffffff; opacity: 0.7;');
    }

    this._gmap = map;
  }

  /**
   * Add data layer to Google map and add onclick if windowProcess is setted
   * @access private
   * @param {ResultsGeoJson} data: ResultsGeoJson data
   * @param {boolean} clear: if clear or not the old data on map
   * @param {boolean} disableFocus: if true disable focus on search results
   */
  addData(data: ResultsGeoJson, clear: boolean = true, disableFocus: boolean = false): void {
    // dati su mappa
    const featArray: FeaturesGeoJson[] = (data.features as FeaturesGeoJson[]) || [];
    // remove old markers
    if (clear) {
      this.clearMap();
    }

    // Check coincident points - Start
    let checkCoincidentPoints = {};
    let coincidentPoints = {};

    featArray.forEach(function(feature) {
      if(feature["geometry"]["coordinates"] && feature["geometry"]["type"] === "Point") {
        var position = new google.maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);

        if(!checkCoincidentPoints[position.toUrlValue()]) {
          checkCoincidentPoints[position.toUrlValue()] = {};
        }

        // Object.assign per creare una copia di "feature" e non inserire l'oggetto "coincidentPoint" in loop dentro "coincidentPoint"
        checkCoincidentPoints[position.toUrlValue()][feature["properties"]["otm_id"]] = Object.assign({}, feature);
      } else if(feature["geometry"]["geometries"]) {
        // Gestione "Point" coincidenti per le "GeometryCollection"
        for(var f = 0; f < feature["geometry"]["geometries"].length; f++) {
          if(feature["geometry"]["geometries"][f]["type"] === "Point") {
            const position = new google.maps.LatLng(feature["geometry"]["geometries"][f]["coordinates"][1], feature["geometry"]["geometries"][f]["coordinates"][0]);

            if(!checkCoincidentPoints[position.toUrlValue()]) {
              checkCoincidentPoints[position.toUrlValue()] = {};
            }

            // Object.assign per creare una copia di "feature" e non inserire l'oggetto "coincidentPoint" in loop dentro "coincidentPoint"
            checkCoincidentPoints[position.toUrlValue()][feature["properties"]["otm_id"]] = Object.assign({}, feature);
          }
        }
      }
    });

    for(let p = 0; p < Object.keys(checkCoincidentPoints).length; p++) {
      if(Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]).length > 1) {
        // console.log('Coincident points');
        // console.log(Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]));

        for(let a = 0; a < Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]).length; a++) {
          let key = Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]])[a];
          let cpObj = checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]];

          coincidentPoints[key] = cpObj;
        }
      } else {
        // console.log('Single point');
        // console.log(Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]));
      }
    }

    // console.log('coincidentPoints');
    // console.log(coincidentPoints);

    featArray.forEach(function(feature) {
      if(feature["properties"]["otm_id"] in coincidentPoints) {
        // feature["coincidentPoint"] contiene un oggetto con:
        // - chiave: otm_id
        // - valore: feature (geometry, properties, type)
        // in cui sono presenti tutti i punti coincidenti con quello corrente (compreso il punto stesso)
        feature["coincidentPoint"] = coincidentPoints[feature["properties"]["otm_id"]];
      } else {
        feature["coincidentPoint"] = null;
      }
    });
    // Check coincident points - End

    featArray.forEach((feature: FeaturesGeoJson, index: number) => {
      const type: string = feature.geometry.type;

      if (type === 'Point') {
        const position: google.maps.LatLng = new google.maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
        // create the markers only if styleProcess exist
        if (this._otm?.options?.styleProcess) {
          const iconObj: StyleProcessResults = this._otm.options.styleProcess(feature, index);
          // set marker only if exist default
          if (iconObj.default) {
            // merge the markeroptions setted in implementation with position search
            const optMarker: google.maps.MarkerOptions = Object.assign(iconObj.default as google.maps.MarkerOptions, {
              position: position,
              animation: google.maps.Animation.DROP
            });
            // create the marker mix with feature and otm_it
            const marker: google.maps.Marker & { otm_id?: string; feature?: FeaturesGeoJson } = Object.assign(
              new google.maps.Marker(optMarker),
              { otm_id: feature.properties.otm_id, feature }
            );

            // Add styleProcess object to manage icon images by status
            marker['otm_styleProcessObject'] = iconObj;

            // Set marker "hover" state (if it exists)
            if(iconObj.hover) {
              var markerCurrentPosition = null;
              var infoWindowCurrentPosition = null;

              marker.addListener('mouseover', () => {
                markerCurrentPosition = marker.getPosition().toUrlValue();
                infoWindowCurrentPosition = this._otm.infoWindowActive ? this._otm.infoWindowActive.getPosition().toUrlValue() : null;

                if((!this._otm.infoWindowActive || (markerCurrentPosition !== infoWindowCurrentPosition)) && marker['otm_currentIconStyle'] !== 'active') {
                  marker.setIcon(iconObj.hover as google.maps.Icon);

                  // Add current style information to marker
                  marker['otm_currentIconStyle'] = 'hover';
                }
              });

              marker.addListener('mouseout', () => {
                markerCurrentPosition = marker.getPosition().toUrlValue();
                infoWindowCurrentPosition = this._otm.infoWindowActive ? this._otm.infoWindowActive.getPosition().toUrlValue() : null;

                if((!this._otm.infoWindowActive || (markerCurrentPosition !== infoWindowCurrentPosition)) && marker['otm_currentIconStyle'] !== 'active') {
                  marker.setIcon((iconObj.default as google.maps.MarkerOptions).icon as google.maps.Icon);

                  // Add current style information to marker
                  marker['otm_currentIconStyle'] = 'default';
                }
              });
            }

            if (this.hasOverlappingSpiderfier()) {
              /** manage spiderfier */
              marker.addListener('spider_click', (event?: MouseEvent) => {
                // Reset "default" status of the previous selected marker
                if(!this.currentActiveMarker) {
                  this.currentActiveMarker = marker;
                } else {
                  this.currentActiveMarker.setIcon((this.currentActiveMarker['otm_styleProcessObject'].default as google.maps.MarkerOptions).icon as google.maps.Icon);

                  // Add current style information to marker
                  this.currentActiveMarker['otm_currentIconStyle'] = 'default';

                  // Update the current active marker
                  this.currentActiveMarker = marker;
                }

                // Trigger the map click to close the markerspiderfier
                google.maps.event.trigger(this._gmap, 'click');

                if(this._otm.options.autoOpenInfoWindow) {
                  // Trigger the map click to close the markerspiderfier
                  /*
                  if(this._otm.infoWindowActive) {
                    google.maps.event.trigger(this._gmap, 'click');
                  }
                  */

                  this.setInfoWindowContent(marker.feature as FeaturesGeoJson);
                  // event.stopPropagation();
                } else {
                  // Trigger the map click to close the markerspiderfier
                  /*
                  google.maps.event.trigger(this._gmap, 'click');
                  */

                  this.setIconStyle(marker.feature as FeaturesGeoJson);
                }
              });
            } else {
              // case MarkerCluster or single markers / coincident points
              //   /** manage open infoWindow */
              marker.addListener('click', () => {
                // Reset "default" status of the previous selected marker
                if(!this.currentActiveMarker) {
                  this.currentActiveMarker = marker;
                } else {
                  if(this._coincidentPointMarkers.length > 0) {
                    for(let cm = 0; cm < this._coincidentPointMarkers.length; cm++) {
                      this._coincidentPointMarkers[cm].setIcon((this._coincidentPointMarkers[cm]['otm_styleProcessObject'].default as google.maps.MarkerOptions).icon as google.maps.Icon);

                      // Add current style information to marker
                      this._coincidentPointMarkers[cm]['otm_currentIconStyle'] = 'default';
                    }

                    this._coincidentPointMarkers = [];

                    // Update the current active marker
                    this.currentActiveMarker = marker;
                  } else {
                    this.currentActiveMarker.setIcon((this.currentActiveMarker['otm_styleProcessObject'].default as google.maps.MarkerOptions).icon as google.maps.Icon);

                    // Add current style information to marker
                    this.currentActiveMarker['otm_currentIconStyle'] = 'default';

                    // Update the current active marker
                    this.currentActiveMarker = marker;
                  }
                }

                if(this._otm.options.autoOpenInfoWindow) {
                  /*
                  if(this._otm.infoWindowActive) {
                    google.maps.event.trigger(this._gmap, 'click');
                  }
                  */

                  if(feature["coincidentPoint"]) {
                    // Coincident points (show the list of all results in this position)
                    this.setInfoWindowContent(marker.feature as FeaturesGeoJson, feature["coincidentPoint"] as object);
                  } else {
                    this.setInfoWindowContent(marker.feature as FeaturesGeoJson);
                  }
                } else {
                  // Trigger the map click to set the default icon style of all markers
                  /*
                  google.maps.event.trigger(this._gmap, 'click');
                  */

                  this.setIconStyle(marker.feature as FeaturesGeoJson);
                }
              });
            }

            this._markers.push(marker);

            // Add marker overlay to current result as "markerOverlay" (with "geometry" and "properties")
            feature['markerOverlay'] = marker;
          }
        } else {
          console.error('State default on marker is not setted');
          return;
        }
      } else if(type === "Polygon") {
        let paths: Array <google.maps.LatLng> = [];

				for(let c = 0; c < feature.geometry.coordinates[0].length; c++) {
					paths.push(new google.maps.LatLng(feature.geometry.coordinates[0][c][1], feature.geometry.coordinates[0][c][0]));
				}

        // Create the polygon only if styleProcess exists
        if(this._otm?.options?.styleProcess) {
          const polygonStyleProcess: StyleProcessResults = this._otm.options.styleProcess(feature, index);

          // Set polygon only if exists default state style
          if(polygonStyleProcess["polygonDefault"]) {
            let polygonOptions = Object.assign({}, polygonStyleProcess["polygonDefault"]);

            // Create the polygon with "feature" and "otm_id"
            polygonOptions["paths"] = paths;

            const polygon: google.maps.Polygon & { otm_id?: string; feature?: FeaturesGeoJson } = Object.assign(
              new google.maps.Polygon(polygonOptions), {
                otm_id: feature["properties"]["otm_id"],
                feature
              }
            );

            // Set polygon "hover" state (if it exists)
	          if(polygonStyleProcess["polygonHover"]) {
	            polygon.addListener('mouseover', () => {
                if(!this.currentActivePolygon || (this.currentActivePolygon && this.currentActivePolygon['otm_id'] !== polygon['otm_id'])) {
                  polygon.setOptions(polygonStyleProcess["polygonHover"]);

                  // Add current style information to polygon
                  polygon['otm_currentPolygonStyle'] = 'hover';
                }
	            });

	            polygon.addListener('mouseout', () => {
                if(!this.currentActivePolygon || (this.currentActivePolygon && this.currentActivePolygon['otm_id'] !== polygon['otm_id'])) {
                  polygon.setOptions(polygonStyleProcess["polygonDefault"]);

                  // Add current style information to polygon
                  polygon['otm_currentPolygonStyle'] = 'default';
                }
	            });
	          }

            polygon.addListener('click', (event) => {
              // Reset "default" status of the previous selected polygon
              if(!this.currentActivePolygon) {
                this.currentActivePolygon = polygon;
              } else {
                this.currentActivePolygon.setOptions(polygonStyleProcess["polygonDefault"]);

                // Add current style information to polygon
                this.currentActivePolygon['otm_currentPolygonStyle'] = 'default';

                // Update the current active polygon
                this.currentActivePolygon = polygon;
              }

              if(this._otm.options.autoOpenInfoWindow) {
                let html: string;

                html = this._otm.options.infoWindowProcess(polygon["feature"]) || '';

                const infowindow: google.maps.InfoWindow = new google.maps.InfoWindow();

                infowindow.setContent(html);

                if(event && event.latLng) {
                  infowindow.setPosition(event.latLng);
                } else {
                  // Approximate position of polygon
                  let polygonBounds = polygon.getPath();
                  let bounds = new google.maps.LatLngBounds();

                  for(let i = 0; i < polygonBounds.length; i++) {
                    let point = {
                      'lat': polygonBounds.getAt(i).lat(),
                      'lng': polygonBounds.getAt(i).lng()
                    };

                    bounds.extend(point);
                  }

                  infowindow.setPosition(bounds.getCenter());
                }

                infowindow.setOptions({ pixelOffset: new google.maps.Size(0, -17) });

                // Dispatch infoWindowDomReady event
                google.maps.event.addListenerOnce(infowindow, 'domready', function () {
                  this._infoDomReadyEvent = new CustomEvent('google.infoWindowDomReady', {
                    detail: polygon["feature"]
                  });

                window.dispatchEvent(this._infoDomReadyEvent);
                });

                this.closeInfoWindow();

                if(html) {
                  infowindow.open(this._gmap);
                }

                this._otm.infoWindowActive = infowindow;

                if(polygonStyleProcess["polygonActive"]) {
                  this.currentActivePolygon.setOptions(polygonStyleProcess["polygonActive"]);

                  // Add current style information to polygon
                  this.currentActivePolygon['otm_currentPolygonStyle'] = 'active';

                  // Add listener to infoWindow on "closeclick"
                  const listenerPolygon: google.maps.MapsEventListener = google.maps.event.addListener(
                    this._otm.infoWindowActive,
                    'closeclick',
                    () => {
                      this.currentActivePolygon.setOptions(polygonStyleProcess["polygonDefault"]);

                      // Add current style information to polygon
                      this.currentActivePolygon['otm_currentPolygonStyle'] = 'default';

                      this._otm.infoWindowActive = undefined;
                      listenerPolygon.remove();

                      this.currentActivePolygon = null;
                    }
                  );
                }
              } else {
                this.setPolygonStyle(polygon.feature as FeaturesGeoJson);
              }
            });

            polygon.setMap(this._gmap);

            this._polygons.push(polygon);

            // Add polygon overlay to current result as "polygonOverlay" (with "geometry" and "properties")
            feature['polygonOverlay'] = polygon;
          }
        } else {
          console.error('State default on polygon is not setted');

          return;
        }
      } else if(type === "GeometryCollection") {
        let pointCounter = 0;
        let polygonCounter = 0;

        for(var f = 0; f < feature["geometry"]["geometries"].length; f++) {
          if(feature["geometry"]["geometries"][f]["type"] === "Point") {
            const position: google.maps.LatLng = new google.maps.LatLng(feature["geometry"]["geometries"][f]["coordinates"][1], feature["geometry"]["geometries"][f]["coordinates"][0]);
            // create the markers only if styleProcess exist
            if (this._otm?.options?.styleProcess) {
              const iconObj: StyleProcessResults = this._otm.options.styleProcess(feature, index);
              // set marker only if exist default
              if (iconObj.default) {
                // merge the markeroptions setted in implementation with position search
                const optMarker: google.maps.MarkerOptions = Object.assign(iconObj.default as google.maps.MarkerOptions, {
                  position: position,
                  animation: google.maps.Animation.DROP
                });
                // create the marker mix with feature and otm_it
                const marker: google.maps.Marker & { otm_id?: string; feature?: FeaturesGeoJson } = Object.assign(
                  new google.maps.Marker(optMarker),
                  { otm_id: feature.properties.otm_id, feature }
                );

                // Set marker "hover" state (if it exists)
                if(iconObj.hover) {
                  var markerCurrentPosition = null;
                  var infoWindowCurrentPosition = null;

                  marker.addListener('mouseover', () => {
                    markerCurrentPosition = marker.getPosition().toUrlValue();
                    infoWindowCurrentPosition = this._otm.infoWindowActive ? this._otm.infoWindowActive.getPosition().toUrlValue() : null;

                    if((!this._otm.infoWindowActive || (markerCurrentPosition !== infoWindowCurrentPosition)) && marker['otm_currentIconStyle'] !== 'active') {
                      marker.setIcon(iconObj.hover as google.maps.Icon);

                      // Add current style information to marker
                      marker['otm_currentIconStyle'] = 'hover';
                    }
                  });

                  marker.addListener('mouseout', () => {
                    markerCurrentPosition = marker.getPosition().toUrlValue();
                    infoWindowCurrentPosition = this._otm.infoWindowActive ? this._otm.infoWindowActive.getPosition().toUrlValue() : null;

                    if((!this._otm.infoWindowActive || (markerCurrentPosition !== infoWindowCurrentPosition)) && marker['otm_currentIconStyle'] !== 'active') {
                      marker.setIcon((iconObj.default as google.maps.MarkerOptions).icon as google.maps.Icon);

                      // Add current style information to marker
                      marker['otm_currentIconStyle'] = 'default';
                    }
                  });
                }

                if (this.hasOverlappingSpiderfier()) {
                  /** manage spiderfier */
                  marker.addListener('spider_click', (event?: MouseEvent) => {
                    // Reset "default" status of the previous selected marker
                    if(!this.currentActiveMarker) {
                      this.currentActiveMarker = marker;
                    } else {
                      this.currentActiveMarker.setIcon((this.currentActiveMarker['otm_styleProcessObject'].default as google.maps.MarkerOptions).icon as google.maps.Icon);

                      // Add current style information to marker
                      this.currentActiveMarker['otm_currentIconStyle'] = 'default';

                      // Update the current active marker
                      this.currentActiveMarker = marker;
                    }

                    // Trigger the map click to close the markerspiderfier
                    google.maps.event.trigger(this._gmap, 'click');

                    if(this._otm.options.autoOpenInfoWindow) {
                      // Trigger the map click to close the markerspiderfier
                      /*
                      if(this._otm.infoWindowActive) {
                        google.maps.event.trigger(this._gmap, 'click');
                      }
                      */

                      /* Add geometry coordinates information of the current Point into feature data - Start */
                      if(!marker.feature['geometry']['coordinates']) {
                        marker.feature['geometry']['coordinates'] = [];
                      }

                      marker.feature['geometry']['coordinates'][1] = position.lat();
                      marker.feature['geometry']['coordinates'][0] = position.lng();
                      /* Add geometry coordinates information of the current Point into feature data - End */

                      this.setInfoWindowContent(marker.feature as FeaturesGeoJson);
                      // event.stopPropagation();
                    } else {
                      // Trigger the map click to close the markerspiderfier
                      /*
                      google.maps.event.trigger(this._gmap, 'click');
                      */

                      this.setIconStyle(marker.feature as FeaturesGeoJson);
                    }
                  });
                } else {
                  // case MarkerCluster or single markers / coincident points
                  //   /** manage open infoWindow */
                  marker.addListener('click', () => {
                    // Reset "default" status of the previous selected marker
                    if(!this.currentActiveMarker) {
                      this.currentActiveMarker = marker;
                    } else {
                      if(this._coincidentPointMarkers.length > 0) {
                        for(let cm = 0; cm < this._coincidentPointMarkers.length; cm++) {
                          this._coincidentPointMarkers[cm].setIcon((this._coincidentPointMarkers[cm]['otm_styleProcessObject'].default as google.maps.MarkerOptions).icon as google.maps.Icon);

                          // Add current style information to marker
                          this._coincidentPointMarkers[cm]['otm_currentIconStyle'] = 'default';
                        }

                        this._coincidentPointMarkers = [];

                        // Update the current active marker
                        this.currentActiveMarker = marker;
                      } else {
                        this.currentActiveMarker.setIcon((this.currentActiveMarker['otm_styleProcessObject'].default as google.maps.MarkerOptions).icon as google.maps.Icon);

                        // Add current style information to marker
                        this.currentActiveMarker['otm_currentIconStyle'] = 'default';

                        // Update the current active marker
                        this.currentActiveMarker = marker;
                      }
                    }

                    if(this._otm.options.autoOpenInfoWindow) {
                      /*
                      if(this._otm.infoWindowActive) {
                        google.maps.event.trigger(this._gmap, 'click');
                      }
                      */

                      /* Add geometry coordinates information of the current Point into feature data - Start */
                      if(!marker.feature['geometry']['coordinates']) {
                        marker.feature['geometry']['coordinates'] = [];
                      }

                      marker.feature['geometry']['coordinates'][1] = position.lat();
                      marker.feature['geometry']['coordinates'][0] = position.lng();
                      /* Add geometry coordinates information of the current Point into feature data - End */

                      if(feature["coincidentPoint"]) {
                        // Coincident points (show the list of all results in this position)
                        this.setInfoWindowContent(marker.feature as FeaturesGeoJson, feature["coincidentPoint"] as object);
                      } else {
                        this.setInfoWindowContent(marker.feature as FeaturesGeoJson);
                      }
                    } else {
                      // Trigger the map click to set the default icon style of all markers
                      /*
                      google.maps.event.trigger(this._gmap, 'click');
                      */

                      this.setIconStyle(marker.feature as FeaturesGeoJson);
                    }
                  });
                }

                this._markers.push(marker);

                // Add marker overlay to current result as "markerOverlay" (with "geometry" and "properties")
                if(!feature['markerOverlay']) {
                  feature['markerOverlay'] = [];
                }

                feature['markerOverlay'][pointCounter] = marker;
                pointCounter++;
              }
            } else {
              console.error('State default on marker is not setted');
              return;
            }
          } else if(feature["geometry"]["geometries"][f]["type"] === "Polygon") {
            let paths: Array <google.maps.LatLng> = [];

            for(let c = 0; c < feature["geometry"]["geometries"][f]["coordinates"][0].length; c++) {
              paths.push(new google.maps.LatLng(feature["geometry"]["geometries"][f]["coordinates"][0][c][1], feature["geometry"]["geometries"][f]["coordinates"][0][c][0]));
            }

            // Create the polygon only if styleProcess exists
            if(this._otm?.options?.styleProcess) {
              const polygonStyleProcess: StyleProcessResults = this._otm.options.styleProcess(feature, index);

              // Set polygon only if exists default state style
              if(polygonStyleProcess["polygonDefault"]) {
                let polygonOptions = Object.assign({}, polygonStyleProcess["polygonDefault"]);

                // Create the polygon with "feature" and "otm_id"
                polygonOptions["paths"] = paths;

                const polygon: google.maps.Polygon & { otm_id?: string; feature?: FeaturesGeoJson } = Object.assign(
                  new google.maps.Polygon(polygonOptions), {
                    otm_id: feature["properties"]["otm_id"],
                    feature
                  }
                );

                // Set polygon "hover" state (if it exists)
                if(polygonStyleProcess["polygonHover"]) {
                  polygon.addListener('mouseover', () => {
                    if(!this.currentActivePolygon || (this.currentActivePolygon && this.currentActivePolygon['otm_id'] !== polygon['otm_id'])) {
                      polygon.setOptions(polygonStyleProcess["polygonHover"]);

                      // Add current style information to polygon
                      polygon['otm_currentPolygonStyle'] = 'hover';
                    }
                  });

                  polygon.addListener('mouseout', () => {
                    if(!this.currentActivePolygon || (this.currentActivePolygon && this.currentActivePolygon['otm_id'] !== polygon['otm_id'])) {
                      polygon.setOptions(polygonStyleProcess["polygonDefault"]);

                      // Add current style information to polygon
                      polygon['otm_currentPolygonStyle'] = 'default';
                    }
                  });
                }

                polygon.addListener('click', (event) => {
                  // Reset "default" status of the previous selected polygon
                  if(!this.currentActivePolygon) {
                    this.currentActivePolygon = polygon;
                  } else {
                    this.currentActivePolygon.setOptions(polygonStyleProcess["polygonDefault"]);

                    // Add current style information to polygon
                    this.currentActivePolygon['otm_currentPolygonStyle'] = 'default';

                    // Update the current active polygon
                    this.currentActivePolygon = polygon;
                  }

                  if(this._otm.options.autoOpenInfoWindow) {
                    let html: string;

                    html = this._otm.options.infoWindowProcess(polygon["feature"]) || '';

                    const infowindow: google.maps.InfoWindow = new google.maps.InfoWindow();

                    infowindow.setContent(html);

                    if(event && event.latLng) {
                      infowindow.setPosition(event.latLng);
                    } else {
                      // Approximate position of polygon
                      let polygonBounds = polygon.getPath();
                      let bounds = new google.maps.LatLngBounds();

                      for(let i = 0; i < polygonBounds.length; i++) {
                        let point = {
                          'lat': polygonBounds.getAt(i).lat(),
                          'lng': polygonBounds.getAt(i).lng()
                        };

                        bounds.extend(point);
                      }

                      infowindow.setPosition(bounds.getCenter());
                    }

                    infowindow.setOptions({ pixelOffset: new google.maps.Size(0, -17) });

                    // Dispatch infoWindowDomReady event
                    google.maps.event.addListenerOnce(infowindow, 'domready', function () {
                      this._infoDomReadyEvent = new CustomEvent('google.infoWindowDomReady', {
                        detail: polygon["feature"]
                      });

                    window.dispatchEvent(this._infoDomReadyEvent);
                    });

                    this.closeInfoWindow();

                    if(html) {
                      infowindow.open(this._gmap);
                    }

                    this._otm.infoWindowActive = infowindow;

                    if(polygonStyleProcess["polygonActive"]) {
                      this.currentActivePolygon.setOptions(polygonStyleProcess["polygonActive"]);

                      // Add current style information to polygon
                      this.currentActivePolygon['otm_currentPolygonStyle'] = 'active';

                      // Add listener to infoWindow on "closeclick"
                      const listenerPolygon: google.maps.MapsEventListener = google.maps.event.addListener(
                        this._otm.infoWindowActive,
                        'closeclick',
                        () => {
                          this.currentActivePolygon.setOptions(polygonStyleProcess["polygonDefault"]);

                          // Add current style information to polygon
                          this.currentActivePolygon['otm_currentPolygonStyle'] = 'default';

                          this._otm.infoWindowActive = undefined;
                          listenerPolygon.remove();

                          this.currentActivePolygon = null;
                        }
                      );
                    }
                  } else {
                    this.setPolygonStyle(polygon.feature as FeaturesGeoJson);
                  }
                });

                polygon.setMap(this._gmap);

                this._polygons.push(polygon);

                // Add polygon overlay to current result as "polygonOverlay" (with "geometry" and "properties")
                if(!feature['polygonOverlay']) {
                  feature['polygonOverlay'] = [];
                }

                feature['polygonOverlay'][polygonCounter] = polygon;
                polygonCounter++;
              }
            } else {
              console.error('State default on polygon is not setted');

              return;
            }
          }
        }
      }
    });

    // gestione markecluster
    if (this.hasMarkerClusterer()) {
      // setto un cluster di default se non viene settato
      if (!(this._otm?.options?.mcOptions as MarkerClustererOptions).imagePath && !this._otm?.options.mcOptions.styles) {
        throw new Error('Marker Cluster must have images');
      }
      if (!this._cluster) {
        this._cluster = new MarkerClusterer(this._gmap, [], this._otm.options.mcOptions as MarkerClustererOptions);
        // set the cluster object in onthemap class
        this._otm.mcCluster = this._cluster;
      }

      // Nel caso di punti coincidenti viene aggiunto solamente un marker all'intero del MarkerCluster
      let markerOnMap: object = {};
      let markersToCluster: Array<any> = [];
      let markerPosition: string = '';

      for(var m = 0; m < this._markers.length; m++) {
        markerPosition = this._markers[m]['position']['toUrlValue']();

        if(markerOnMap[markerPosition] !== true) {
          markersToCluster.push(this._markers[m]);
          markerOnMap[markerPosition] = true;
        }
      }

      /** add markers */
      this._cluster.addMarkers(markersToCluster);

      /** FIX FOR FIT MAP */
      if(!disableFocus) {
        setTimeout(() => {
          if (!!this._markers.length) this._cluster.fitMapToMarkers(10);
        }, 0);
      }
    }
    // gestione Overlapping Marker Spiderfier
    else if (this.hasOverlappingSpiderfier()) {
      this._oms = new OverlappingMarkerSpiderfier(this._gmap, this._otm?.options?.osOptions);
      this._otm.osCluster = this._oms; // set the spiderfier in otnhemap class
      this._markers.forEach((marker: google.maps.Marker & { otm_id?: string; feature?: FeaturesGeoJson }) => {
        //@ts-ignore addMarker don't need mandatory handler, see https://github.com/jawj/OverlappingMarkerSpiderfier
        this._oms.addMarker(marker);
      });
    }
    // senza markercluster
    else {
      this._markers.forEach((marker: google.maps.Marker & { otm_id?: string; feature?: FeaturesGeoJson }) => {
        marker.setMap(this._gmap);
      });
    }

    this._otm.featureCollection = [...featArray];
  }

  /**
   * Set the content of an infoWindow alias balloon and open it
   * @access private
   * @param {FeaturesGeoJson} feat - feature alias one item result
   */
  setInfoWindowContent(feat: FeaturesGeoJson, coincidentPoints: object) {
    // const html = this._otm.options.infoWindowProcess(feat) || '';
    let html: string;

    /** Se è stata chiamata la funzione "openInfoWindow", oppure se viene utilizzato l'Overlapping Marker Spiderfier
     *  viene aperto direttamente il balloon del risultato corrispondente all'otm_id selezionato.
    */
    if(this.fromOpenInfoWindow || this.hasOverlappingSpiderfier()) {
      let tmp = Object.assign({}, feat);

      if(tmp['coincidentPoint']) {
        tmp['coincidentPoint'] = null;
      }

      html = this._otm.options.infoWindowProcess(tmp) || '';

      if(this.fromOpenInfoWindow) {
        this.fromOpenInfoWindow = false;
      }
    } else {
      html = this._otm.options.infoWindowProcess(feat) || '';
    }

    const infowindow: google.maps.InfoWindow = new google.maps.InfoWindow();

    infowindow.setContent(html);
    infowindow.setPosition(new google.maps.LatLng(feat.geometry.coordinates[1], feat.geometry.coordinates[0]));
    infowindow.setOptions({ pixelOffset: new google.maps.Size(0, -34) });

    /**
     * dispatch the infoWindowDomReady event
     */
    let self = this;

    google.maps.event.addListenerOnce(infowindow, 'domready', function () {
      this._infoDomReadyEvent = new CustomEvent('google.infoWindowDomReady', { detail: feat });

      if(coincidentPoints) {
        let elementsArray = document.querySelectorAll("#coincident-points li a");

        elementsArray.forEach(function(element) {
          element.addEventListener("click", function() {
            let otm_id = this.getAttribute("data-otm-id");

            for(let i in coincidentPoints) {
              if(i === otm_id) {
                self.setInfoWindowContent(coincidentPoints[i]);
              }
            }
          });
        });
      }

      window.dispatchEvent(this._infoDomReadyEvent);
    });

    this.closeInfoWindow();

    if (this._otm.options.autoOpenInfoWindow) {

      // apre l'infowindow solo se ha contenuto
      if(html) {
        infowindow.open(
          this._gmap,
          new google.maps.Marker({ position: new google.maps.LatLng(feat.geometry.coordinates[1], feat.geometry.coordinates[0]) })
        );
      }

      this._otm.infoWindowActive = infowindow;

      const iconObj: StyleProcessResults = this._otm.options.styleProcess(feat);

      // get the marker of feat
      if (iconObj.active) {
        const markerToManage: Array<google.maps.Marker & { otm_id?: string; feature?: FeaturesGeoJson }> = this._markers.filter(
          (marker: google.maps.Marker & { otm_id?: string; feature?: FeaturesGeoJson }) => {
            const id = marker.otm_id;

            if(coincidentPoints) {
              if(id in coincidentPoints) {
                for(let i in coincidentPoints) {
                  return true;
                }
              } else {
                return false;
              }
            } else {
              return id === feat.properties?.otm_id;
            }
          }
        );

        /*
        if (markerToManage.length > 1) {
          console.error('setInfoWindowContent: error on select marker to activated');
          return;
        }
        */

        if(coincidentPoints) {
          this._coincidentPointMarkers = markerToManage;
        }

        for(let m = 0; m < markerToManage.length; m++) {
          markerToManage[m].setIcon(iconObj.active as google.maps.Icon);

          // Add current style information to marker
          markerToManage[m]['otm_currentIconStyle'] = 'active';

          /** add listener to infoWindow on closeclick */
          const listenerMarker: google.maps.MapsEventListener = google.maps.event.addListener(
            this._otm.infoWindowActive,
            'closeclick',
            () => {
              if(this._coincidentPointMarkers.length > 0) {
                for(let cm = 0; cm < this._coincidentPointMarkers.length; cm++) {
                  this._coincidentPointMarkers[cm].setIcon((iconObj.default as google.maps.MarkerOptions).icon as google.maps.Icon);

                  // Add current style information to marker
                  this._coincidentPointMarkers[cm]['otm_currentIconStyle'] = 'default';
                }

                this._otm.infoWindowActive = undefined;
                listenerMarker.remove();
              } else {
                markerToManage[m].setIcon((iconObj.default as google.maps.MarkerOptions).icon as google.maps.Icon);

                // Add current style information to marker
                markerToManage[m]['otm_currentIconStyle'] = 'default';

                this._otm.infoWindowActive = undefined;
                listenerMarker.remove();
              }
            }
          );
        }

        /** add listener to fix click on other location in map */
        /*
        const listenerMap: google.maps.MapsEventListener = google.maps.event.addListener(this._gmap, 'click', () => {
          markerToManage[0].setIcon((iconObj.default as google.maps.MarkerOptions).icon as google.maps.Icon);

          // Add current style information to marker
          markerToManage[0]['otm_currentIconStyle'] = 'default';

          this.closeInfoWindow();
        });
        */
      }
    }
  }

  /**
   * Set the "active" icon style of selected marker
   * @access private
   * @param {FeaturesGeoJson} feat - feature alias one item result
   */
   private setIconStyle(feat: FeaturesGeoJson): void {
    const iconObj: StyleProcessResults = this._otm.options.styleProcess(feat);

    if(iconObj.active) {
      const markerToManage: Array<google.maps.Marker & { otm_id?: string; feature?: FeaturesGeoJson }> = this._markers.filter(
        (marker: google.maps.Marker & { otm_id?: string; feature?: FeaturesGeoJson }) => {
          const id = marker.otm_id;
          return id === feat.properties?.otm_id;
        }
      );

      if(markerToManage.length > 1) {
        console.error('setIconStyle: error on selecting the marker to activate');

        return;
      }

      markerToManage[0].setIcon(iconObj.active as google.maps.Icon);

      // Add current style information to marker
      markerToManage[0]['otm_currentIconStyle'] = 'active';

      /** Set the default icon style to the marker when user click on map */
      /*
      const listenerMap: google.maps.MapsEventListener = google.maps.event.addListener(this._gmap, 'click', () => {
        markerToManage[0].setIcon((iconObj.default as google.maps.MarkerOptions).icon as google.maps.Icon);

        // Add current style information to marker
        markerToManage[0]['otm_currentIconStyle'] = 'default';
      });
      */
    }
   }

   private setPolygonStyle(feat: FeaturesGeoJson): void {
    const polygonStyleProcess: StyleProcessResults = this._otm.options.styleProcess(feat);

    if(polygonStyleProcess["polygonActive"]) {
      this.currentActivePolygon.setOptions(polygonStyleProcess["polygonActive"]);

      // Add current style information to polygon
      this.currentActivePolygon['otm_currentPolygonStyle'] = 'active';
    }
   }

  /**
   * close the infoWindowActive
   * @access private
   */
  private closeInfoWindow(): void {
    if (this._otm.infoWindowActive) {
      (this._otm.infoWindowActive as google.maps.InfoWindow).close();
      this._otm.infoWindowActive = undefined;
    }
  }

  /**
   * Remove data from google map
   * @access private
   */
  clearMap(): void {
    this._markers.forEach((marker: google.maps.Marker) => {
      marker.setMap(null);
    });
    this._cluster && this._cluster.clearMarkers();
    this._markers = [];
    this._coincidentPointMarkers = [];

    this._polygons.forEach((polygon: google.maps.Polygon) => {
      polygon.setMap(null);
    });
    this._polygons = [];

    this.closeInfoWindow();
  }

  /**
   * focus on bounds of google map
   * @access private
   * @param {FeaturesGeoJson} featureCollection: the features collection
   */
  focus(featureCollection: Array<FeaturesGeoJson>, search_position?, decodedPath?): void {
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    if (!!featureCollection.length) {
      featureCollection.forEach((feature: FeaturesGeoJson) => {
        let latlng: google.maps.LatLng;

        if(feature["geometry"]["type"] === "Point") {
          latlng = new google.maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
          bounds.extend(latlng);
        } else if(feature["geometry"]["type"] === "Polygon") {
          let paths: Array <google.maps.LatLng> = [];

					for(let c = 0; c < feature["geometry"]["coordinates"][0].length; c++) {
					  paths.push(new google.maps.LatLng(feature["geometry"]["coordinates"][0][c][1], feature["geometry"]["coordinates"][0][c][0]));
					}

          for(let c = 0; c < paths.length; c++) {
            bounds.extend(paths[c]);
          }
        } else if(feature["geometry"]["type"] === "GeometryCollection") {
            for(let i = 0; i < feature["geometry"]["geometries"].length; i++) {
              if(feature["geometry"]["geometries"][i]["type"] === "Point") {
                latlng = new google.maps.LatLng(feature["geometry"]["geometries"][i]["coordinates"][1], feature["geometry"]["geometries"][i]["coordinates"][0]);
                bounds.extend(latlng);
              } else if(feature["geometry"]["geometries"][i]["type"] === "Polygon") {
                let paths: Array <google.maps.LatLng> = [];

                for(let c = 0; c < feature["geometry"]["geometries"][i]["coordinates"][0].length; c++) {
                  paths.push(new google.maps.LatLng(feature["geometry"]["geometries"][i]["coordinates"][0][c][1], feature["geometry"]["geometries"][i]["coordinates"][0][c][0]));
                }

                for(let c = 0; c < paths.length; c++) {
                  bounds.extend(paths[c]);
                }
              }
            }
        }
      });

      // estensione bounds all'indirizzo ricercato / al percorso ricercato
      if(search_position && search_position.length == 2) {
        let search_position_latlng =  new google.maps.LatLng(search_position[0], search_position[1]);
        bounds.extend(search_position_latlng);
      } else if(decodedPath) {
        for(let p = 0; p < decodedPath.length; p++) {
          bounds.extend(new google.maps.LatLng(decodedPath[p].lat(), decodedPath[p].lng()));
        }
      }

      setTimeout(() => {
        this._gmap.fitBounds(bounds);
      }, 100);
    } else {
      // this._gmap.fitBounds(bounds);
      // this._gmap.getBounds
      // this._gmap.setZoom(5);
      // console.log('[google zoom]', this._gmap.getZoom());
    }
  }

  /**
   * It open an infoWindow for a specific id
   * @access private
   * @param {string} otm_id: otm_id of a feature to open in infoWindow
   * @param {Array<FeaturesGeoJson>} featureCollection: collections of features result of the last search
   */
  openInfoWindow(otm_id: string, featureCollection: FeaturesGeoJson[]): void {
    let found = false;
    this.fromOpenInfoWindow = true;

    if (!otm_id) {
      console.error('openInfoWindow:no otm_id provided');
      return;
    }
    const feature: FeaturesGeoJson = featureCollection.filter((feat) => {
      return otm_id === feat.properties.otm_id;
    })[0];
    this._markers.forEach((marker: google.maps.Marker & { otm_id?: string; feature?: FeaturesGeoJson }) => {
      const id = marker.otm_id;

      if (id === otm_id) {
        found = true;
        this._gmap.setCenter(marker.getPosition());
        this._gmap.setZoom(15);

        if (this.hasOverlappingSpiderfier()) {
          // trick for open the also spider
          google.maps.event.trigger(marker, 'spider_click', event);
        } else {
          google.maps.event.trigger(marker, 'click');
        }
        return;
      }
    });

    // Se non è stato trovato un marker con l'otm_id indicato si effettua una ricerca su eventuali poligoni
    if(!found) {
      this._polygons.forEach((polygon: google.maps.Polygon & { otm_id?: string; feature?: FeaturesGeoJson }) => {
        const id = polygon.otm_id;

        if (id === otm_id) {
          found = true;
          // Approximate position of polygon
          let polygonBounds = polygon.getPath();
          let bounds = new google.maps.LatLngBounds();

          for(let i = 0; i < polygonBounds.length; i++) {
            let point = {
              'lat': polygonBounds.getAt(i).lat(),
              'lng': polygonBounds.getAt(i).lng()
            };

            bounds.extend(point);
          }

          this._gmap.setCenter(bounds.getCenter());
          this._gmap.setZoom(15);

          google.maps.event.trigger(polygon, 'click');

          return;
        }
      });
    }
  }

  /**
   * Create the autocomplete element and return the address object to callback
   * @access private
   * @param {HTMLInputElement} container - input element html container the autocomplete functionality
   * @param {google.maps.places.AutocompleteOptions} options - options for autocomplete element: see {@link google.maps.places.AutocompleteOptions}, for OpenStreetMap provider the type is {@link ProviderParams}
   * @param {Function} callback - function called
   */
  setAutocomplete(container: HTMLInputElement, options: google.maps.places.AutocompleteOptions, callback: Function): void {
    const autocomplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
      container,
      options as google.maps.places.AutocompleteOptions
    );

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const places: google.maps.places.PlaceResult = autocomplete.getPlace();

      if (!places.geometry) {
        this.geocode(container.value, (results: google.maps.GeocoderResult[]) => {
          callback(results);
        });
      } else {
        if (callback && typeof callback == 'function') {
          callback(places);
        }
      }
    });
  }

  /**
   * geocode an address @todo geocode the address
   * @access private
   * @param {string} value: address string
   * @param {ProviderParams} options: params for openstreetmap provider
   * @param {Function} callback: callback function
   */
  geocode(value: string, options?: any, callback?: Function): void {
    const geocoder = new google.maps.Geocoder();
    let output: google.maps.GeocoderResult[] = new Array();

    geocoder.geocode({ address: value }, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
      if (status === google.maps.GeocoderStatus.OK) {
        output = results;
      } else {
        console.error('Address not found');
      }

      if (callback && typeof callback == 'function') {
        callback(output);
      }
    });
  }

  /**
   * return true if markerClusterer is setted, otherwise false
   * @private
   * @returns boolean
   */
  private hasMarkerClusterer(): boolean {
    return !!this._otm?.options?.mcOptions;
  }

  /**
   * return true if overlappingSpiderfier is setted, otherwise false
   * @private
   * @returns boolean
   */
  private hasOverlappingSpiderfier(): boolean {
    return !!this._otm?.options?.osOptions;
  }
}
