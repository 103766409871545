import axios, { AxiosResponse } from 'axios';
import { SearchOptions } from '../interfaces/Search';

/**
 * Classe di servizio per le chiamate alle API REST di OnTheMap
 * @class APIService
 *
 */
export default class APIService {
  private host: string;
  private path: string;

  constructor(protocol: string = 'https://', domain: string = 'api.onthemap.io') {
    this.host = protocol + domain;
    this.path = '/server/v1/api/';
  }

  /**
   * Search service
   * @memberof APIService
   * @param params: object params for search interface SearchOptions
   * @returns Promise<AxiosResponse>
   */
  public search(params: SearchOptions): Promise<AxiosResponse> {
    let url = `${this.host}${this.path}location?${this.buildParams(params)}`;
    return axios.get(url);
  }

  /**
   * Gestione distinct di una colonna
   * @memberof APIService
   * http://{{host}}/server/v1/api/location/values/json?table=prova&schema=geoschema&offset=0&hits=5&field=comune
   * @param params: the search params
   * @returns Promise<AxiosResponse>
   */
  public searchValues(params: object): Promise<AxiosResponse> {
    let url = `${this.host}${this.path}location/values/json?${this.buildParams(params)}`;
    return axios.get(url);
  }

  /**
   * Search along path
   *    esempio di params? {"overview_polyline" : \{
   *       		"points" : "\}zntGaxaw\@eXnVcGqg@kPmm@~Mkc@pr@cG`BauD`Mu|AnfA{p@taBbc@`d@pl@te@mAhrAi}ApkA`Dp|@ko@pnCogDpmCidEnt@{{EhbEcfHbxJkoP|dJafNjcH}|LvzD}xGh~CajDzlHmiH|pCujDboBwyGh~EwnO|nF{eOxdCyiH|h@esGtgCynX~{AgdKnrAgzIzxCidLpmJk{XxmAkkD~hAkvHroBm}OzsDsbPbmCkkJriF_pC~z@o`BroBwfGduCgsMv{BqwKjbCo}IhdEsoPr|AmuE~|AgvAbiAsc@raDhYxgDdkAldBhWbhBjO|vC{~@lgExhBdxA`aAlh@biDnqA~`B|hBzz@`lAkc@lqAnm@~oAqAndAs~@xxB}V`u@nOly@pYf`AwSz|ApObz@|Mdm@qJl`C{vBhgDamB~eAul@rfAmj@|lAvSbrAvcBlnAh]~lB~VzhCr`@dbDpLxeCopAx_AdYrk@bz@|w@zqAxoAtT~lAzg@rn@pp@v}@|Klk@sNtrArs@zzBfgB`jC{Fr}HukBn}@mo@jnAycAjcAwjHobAwaCeu@{_Bs@ohArb@wtAh[{jCx@}uHncA}lAj}@}t@f}A}fB}CynAdU_iBlq@iy@nwAav@xeBcr@hhA_A~x@}j@rjEsbE|cCytDrp@sw@zw@t@d`AotAf|C{bGzz@srBT}_B|`@k|AtfAehBd_AipDvXiwDbiAu|BprBauAf}@qgBpbAyd@`q@`DhjCxbB`uCfYdiCop@|yDbO|vDeFl_Ewb@rd@zEb|Agr@fnBa{Bt`NmcJztCkrBrp@Qhx@uv@j_EctA~xE}sAxq@nH`s@ul@~sAzSphBo~@j_A}LjtAyhAlpCetA|hC}pApbBauA~uBmxEduBq_AnrAkqBjvB}QviB`F~`@ll@b{@}NheAahAzeD}Z~eBcdCryAuaCv{@{vCb{@stCjbBadCp_@o{Dvf@gvApiB{Q`kAou@jXcZlg@pDfrBgg@jhImoAvbEqwAhgD{y@veD}yHvmBmzCzs@}{@jZm}BbI{sBdc@qj@zp@sw@jV_`A`p@yTveBmfB`tC{oCzvBkmAdbF`Ff`AhQ~~@{zB`mAsm@bk@xGphAgb@nxAynErr@uiB|qA{j@jbAk|Cvv@cw@nv@sFhjA{j@jrAcV`oBI`~A{}@~d@_U~hAhZtnCrrAvxCdyAlrBliCpz@v{BlcAni@bvD`SrbHl_@pOvb@i^vq@twAbh@leEkEx{AyH~nAplAr]bOnj@gJ~OcEw@A"
   *       		\}\}
   * @memberof APIService
   * @param path: path per la chiamata
   * @param params: oggetto parametri di ricerca

   */
  public searchByDirection(params: object, path: any): Promise<AxiosResponse> {
    let url = this.host + this.path + 'location/search/json?' + this.buildParams(params);
    return this.post(url, path);
  }

  /**
   * Get di tutti i media di una determinata location
   * https://{{host}}/server/v1/api/location/media?otm_id=0001
   * @memberof APIService
   * @param params: @interface SearchOptions
   * @returns axios Promise
   */
  public getImages(params: SearchOptions): Promise<any> {
    let url = this.host + this.path + 'location/media?' + this.buildParams(params);
    return axios.get(url);
  }

  /**
   * build string params
   * @memberof APIService
   * @param params: params object to stringify
   * @returns string
   */
  public buildParams(params: object): string {
    let out = [];
    for (var k in params) out.push(k + '=' + encodeURIComponent(params[k]));

    return out.join('&');
  }

  /**
   * call post xios function
   * @memberof APIService
   * @param url url string to call
   * @param body body for REST post
   * @private
   */
  public post(url: string, body: any): Promise<AxiosResponse> {
    return axios.post(url, body);
  }
}
