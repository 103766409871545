import APIService from '../services/api.service';
import { OnTheMapOptions, OtmOptionsPrivate } from '../interfaces/OnTheMap';
import { FeaturesGeoJson, ResponseGeneral, ResultsGeoJson } from '../interfaces/Responses';
import { SearchOptions, SearchValuesParams } from '../interfaces/Search';
import { AxiosResponse, AxiosError } from 'axios';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { FeatureGroup, Map } from 'leaflet';
import { ProviderClass } from '../interfaces/ProviderClass';
import { ProviderParams } from 'leaflet-geosearch/dist/providers/provider';
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';
import GoogleMap from './googleMap';
import OsmMap from './osmMap';
import KakaoMap from './kakaoMap';
import { KakaoAucompleteOptions, KakaoGeocoderOptions } from '../interfaces/Kakao';
import { EventWithDetail } from './../interfaces/EventWithDetail';
import { BaiduMap } from './baiduMap';

/**
 * @namespace noovle
 * @description
 * Namespace created in window object, after inclusion of OTM javascript API.
 * <br>Inside this namespace you can find the [main class onthemap]{@link OnTheMap}
 * @example
 * <caption>The namespace noovle in your HTML page</caption>
 * <head>
 *   <script src="https://api.onthemap.io/server/v1/api/"></script>
 * </head>
 * <script>
 * const otm = new noovle.onthemap(....)
 * </script>
 */

/**
 * @classdesc This class is the point of acces of all functionality of OnTheMap API.<br>
 * To use the API, first of all, you must include the OTM javascript API (you can see a [tutorial](tutorial-include-api.html) to understand how).<br>
 * The API inclusion creates a namespace called [__noovle__]{@link noovle} where inside you can find this class __onthemap__ as its member.<br>
 * After that, you must create an instance of __onthemap__ class by passing two mandatory parameters: [OnTheMapOptions]{@link OnTheMapOptions} object and the [Map]{@link OnTheMap#map} element.<br>
 * You can see more details on how to instance the **onthemap** class in [tutorial](tutorial-istantiate-onthemap-class.html).<br><br>
 * @class OnTheMap
 * @param {OnTheMapOptions} OnTheMapOptions - Object configuration for istantiate new OnTheMap class.
 * @param {google.maps.Map | Map | BMap.Map | kakao.maps} map - Map object, see [map documentation]{@link OnTheMap#map} for details.
 *
 */

/**
 * Used to fix jsDoc issue for using "#" private class feature
 */
('use strict');
export default class OnTheMap {
  /**
   * Object of possibile providers map provider name with provider class
   * @access private
   * @enum {Object} #MAP_CLASSES
   * @readonly
   * @property {Object} #MAP_CLASSES - Object enum provider
   * @property {string} #MAP_CLASSES.google - select the 'google' provider class
   * @property {string} #MAP_CLASSES.osm - select the 'openstreetmap' provider class
   * @property {string} #MAP_CLASSES.baidu - select the 'baidu' provider class
   * @property {string} #MAP_CLASSES.kakao - select the 'kakao' provider class
   */
  readonly #MAP_CLASSES = {
    google: GoogleMap,
    osm: OsmMap,
    kakao: KakaoMap,
    baidu: BaiduMap,
  };

  /**
   * Options used for configure OnTheMap class
   * For details see {@link OnTheMapOptions} interface
   * @access private
   */
  options: OnTheMapOptions;

  /**
   * Map object. Its type is dipending on provider, it should be [Google]{@link https://developers.google.com/maps/documentation/javascript/reference/map} or a [Openstreetmap]{@link https://leafletjs.com/reference-1.7.1.html} or [Baidu]{@link https://lbsyun.baidu.com/cms/jsapi/reference/jsapi_reference.html} or [Kakao]{@link https://apis.map.kakao.com/web/guide/}.
   */
  #map: google.maps.Map | Map | kakao.maps.Map;

  /**
   * Array of features (search results).<br>
   * This array is filled after a search and after added data to map.<br>
   * The type (interface) is different by providers, see specific interface for [google, kakao and baidu]{@link ResponseGeneral.ResponseGeoJson.ResultsGeoJson.FeaturesGeoJson} providers and [leaflet]{@link https://leafletjs.com/reference-1.7.1.html#featuregroup} provider.
   * @type {FeatureGroup | FeaturesGeoJson[]}
   * @memberof OnTheMap
   */
  get featureCollection(): FeatureGroup | FeaturesGeoJson[] {
    return this.#__otm.featureCollection;
  }

  /**
   * set the Feature collection
   * @private
   */
  set featureCollection(featColl: FeatureGroup | FeaturesGeoJson[]) {
    this.#__otm.featureCollection = featColl;
  }

  /**
   * the InfoWindow object that is opened in map.
   * <br>For details:
   * - [google]{@link https://developers.google.com/maps/documentation/javascript/reference/info-window}
   * - [OpenStreetMap]{@link https://leafletjs.com/reference-1.7.1.html#popup}
   * - [kakao]{@link https://apis.map.kakao.com/web/documentation/#InfoWindow},
   * - [baidu]{@link https://mapopen-pub-jsapi.bj.bcebos.com/jsapi/reference/jsapi_reference_3_0.html#a3b7}
   * @type {google.maps.InfoWindow | L.Popup | kakao.maps.InfoWindow | BMap.InfoWindow}
   * @memberof OnTheMap
   */
  get infoWindowActive(): google.maps.InfoWindow | L.Popup | kakao.maps.InfoWindow | BMap.InfoWindow {
    return this.#__otm.infoWindowActive;
  }

  /**
   * set the infoWindow object
   * @private
   */
  set infoWindowActive(infoW: google.maps.InfoWindow | L.Popup | kakao.maps.InfoWindow | BMap.InfoWindow) {
    this.#__otm.infoWindowActive = infoW;
  }

  /**
   * MarkerClusterer object, see [MarkerClusterer for google](https://www.npmjs.com/package/@googlemaps/markerclustererplus) or [MarkerCluster for Kakao](https://apis.map.kakao.com/web/documentation/#MarkerClusterer)
   * @type MarkerClusterer | kakao.maps.services.MarkerCluster
   * @memberof OnTheMap
   */
  get mcCluster(): MarkerClusterer | kakao.maps.services.MarkerCluster {
    return this.#__otm.mcCluster;
  }

  /**
   * set MarkerClusterer | kakao.maps.services.MarkerCluster
   * @private
   */
  set mcCluster(mc: MarkerClusterer | kakao.maps.services.MarkerCluster) {
    this.#__otm.mcCluster = mc;
  }

  /**
   * [ONLY FOR GOOGLE] OverlappingMarkerSpiderfier object, see [the ghitub project for detail]{@link https://github.com/jawj/OverlappingMarkerSpiderfier}
   * @type {OverlappingMarkerSpiderfier}
   * @memberof OnTheMap
   */
  get osCluster(): OverlappingMarkerSpiderfier {
    return this.#__otm.osCluster;
  }

  /**
   * set Overlay Spider Cluster Only for GOOGLE
   * @type {OverlappingMarkerSpiderfier}
   * @private
   */
  set osCluster(os: OverlappingMarkerSpiderfier) {
    this.#__otm.osCluster = os;
  }

  /**
   * return the current host name
   * @private
   */
  #currentHost: string;

  /**
   * Object container for all private properties/Object
   * @type {OtmOptionsPrivate}
   * @private
   */
  #__otm: OtmOptionsPrivate;

  constructor(otmOptions: OnTheMapOptions, map: any) {
    /** set the otm option in internal variable */
    this.options = otmOptions;

    // set default true for auto open info window
    this.options.autoOpenInfoWindow = typeof this.options.autoOpenInfoWindow !== 'undefined' ? this.options.autoOpenInfoWindow : true;

    /** set the default provider if not setted */
    const prioviderName: string = this.options.provider || 'google';

    /** set the current host used internally */
    this.#currentHost = window.location.hostname;

    /**
     * set services and provider in #__otm internal object
     */
    this.#__otm = {
      provider: new this.#MAP_CLASSES[prioviderName](this),
      service: new APIService(this.options.protocol, this.options.domain),
      featureCollection: [],
    };

    // set map in internal variable
    this.#map = map;
    //@ts-ignore because we can call 4 different types of map but we dont know which
    this.#__otm.provider.setupMap(this.#map, this.options.hideOnTheMapLogo);
  }

  /**
   * callback function called after data return
   * @callback getDataCallback
   * @param {ResponseGeneral} results - results of search
   */

  /**
   * Search the data by search options and return results in callback.<br>
   * **This function do not add results to map**.
   * @memberof OnTheMap
   * @param {SearchOptions} params - params to query
   * @param {getDataCallback} [callback] - callback with results in params
   * @example
   * otm.getData(
        {
          query: "[Località]=[Milano]",
        },
        function (data) {
          manage(data);
        }
      );
   */
  public getData(params?: SearchOptions, callback?: Function): void {
    if (!params.key) {
      params.key = this.options?.key;
    }
    this.#__otm.service
      .search(params)
      .then((data: AxiosResponse) => {
        const results: ResponseGeneral = data.data;
        if (results.status !== 'OK') {
          // errore
          console.error(results.error_message);
        }
        if (callback && typeof callback == 'function') {
          callback(results);
        }
      })
      .catch((error: AxiosError) => {
        console.error(error.message);
      });
  }

  /**
   * Callback function called after the serach is done and is OK, it has in params the results of search
   *
   * @callback searchCallback
   * @param {ResponseGeneral} results - results of the search
   */

  /**
   * Search data and add results to map. You can choose if remove olds result from map with clear params.
   * @memberof OnTheMap
   * @param {SearchOptions} [params] - [search params]{@link SearchOptions} for query data
   * @param {boolean} [clear = true] - boolean if clear or not
   * @param {searchCallback} [callback] - callback function, it has results in params
   * @param {boolean} [isDistanceMatrix = false] @private - boolean if the request came from ditsanceMatrix or default search
   */
  public search(params?: SearchOptions, clear: boolean = true, callback?: Function, isDistanceMatrix: boolean = false): void {
    if (!params.key) {
      params.key = this.options?.key;
    }
    // channel for statistic
    params.channel = this.#currentHost;

    this.#__otm.service
      .search(params)
      .then((data: AxiosResponse) => {
        const results: ResponseGeneral = data.data;

        // inserisce i dati su mappa
        if (results.status === 'OK') {
          if (!isDistanceMatrix) {
            this.#__otm.provider.addData(results.data.results as ResultsGeoJson, clear, params.disable_focus ? params.disable_focus : undefined);

            // focus dei dati
            if(!params.disable_focus) {
              let search_position = null;
              if(params["lat"] && params["lng"]) {
                search_position = [ params["lat"], params["lng"] ];
              }

              this.focus(search_position);
            }
          }
        } else {
          // errore
          console.error(results.error_message);
        }
        if (callback && typeof callback == 'function') {
          callback(results);
        }
      })
      .catch((error: AxiosError) => {
        console.error(error.message);
      });
  }

  /**
   * Callback function called after the serach values is done and is OK, it has in params the results of search
   *
   * @callback searchValuesCallback
   * @param {ResponseGeneral} results - results of the search values
   */

  /**
   * Find distinct results for one specific otm field.
   * @memberof OnTheMap
   * @param {SearchValuesParams} params - search params
   * @param {searchValuesCallback}[callback] - callback function called after search. It has results of search in input params.
   * @example
   * otm.searchValues(
   *    { field: "Area amministrativa" },
   *    function (data) {
   *      manage(data);
   *    }
   *  );
   */
  public searchValues(params: SearchValuesParams, callback?: Function): void {
    if (!params.key) params.key = this.options?.key;
    // channel for statistic
    params.channel = this.#currentHost;

    this.#__otm.service
      .searchValues(params)
      .then((data: AxiosResponse) => {
        const results: ResponseGeneral = data.data;

        if (results.status !== 'OK') {
          // errore
          console.error(results.error_message);
        }
        if (callback && typeof callback == 'function') {
          callback(results);
        }
      })
      .catch((error: AxiosError) => {
        console.error(error.message);
      });
  }

  /**
   * Callback function called after the serach images is done and is OK, it has in params the results of search
   *
   * @callback getImageCallback
   * @param {ResponseGeneral} results - results of the search images
   */

  /**
   * Search images in specific point or location.
   * Results are passed in call back function
   * @memberof OnTheMap
   * @param {SearchOptions} [params] - search options for search images
   * @param {getImageCallback} [callback] - callback function with results as params
   * @example
   * otm..getImages(
        {
          id_otm: id_otm,
        },
        function (data) {
          manage(data);
        }
      );
   */
  public getImages(params?: SearchOptions, callback?: Function): void {
    if (!params.key) params.key = this.options?.key;
    // channel for statistic
    params.channel = this.#currentHost;

    this.#__otm.service
      .getImages(params)
      .then((data: AxiosResponse) => {
        const results = data.data;
        if (results.status !== 'OK') {
          // errore
          console.error(results.error_message);
        }
        if (callback && typeof callback == 'function') {
          callback(results);
        }
      })
      .catch((error: AxiosError) => {
        // caso errore
        console.error(error.message);
      });
  }

  /**
   * Callback function called after the serach by direction is done and is OK, it has in params the results of search
   *
   * @callback searchByDirectionCallback
   * @param {ResponseGeneral} results - results of the search by direction
   */
  /**
   * **GOOGLE PROVIDER ONLY**<br>Search data around specific direction
   * @memberof OnTheMap
   * @param {SearchOptions} params - Params for search
   * @param {google.maps.DirectionsRoute} path - object response of google directions service search, <a href="https://developers.google.com/maps/documentation/directions/get-directions?hl=en#DirectionsResponse" target="_blank">see google.maps.DirectionsRoute object.</a>
   * @param {boolean} [clear=true] -  if you want clear old cearch
   * @param {FuncsearchByDirectionCallbacktion} [callback] - callback function called at the end of execution. It has results of search in input params.
   * @example
   * const directionsService = new google.maps.DirectionsService();
   * const directionsRenderer = new google.maps.DirectionsRenderer();
   * directionsRenderer.setMap(googleMap);

   * directionsService.route(
        {
        origin: { query: "Milano, Mi, Italia" },
        destination: { query: "Firenze, Fi, Italia" },
        travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK") {
          directionsRenderer.setDirections(response);

          var overview_polyline = response.routes[0].overview_polyline;
          var route = { overview_polyline: { points: overview_polyline } };

               otm.searchByDirection(
               {
                 radius: "10000",
                 fields:
                   "Codice postale, Descrizione, otm_media, otm_data_address, otm_id",
               },
               route,
               false,
               function (data) {
                 manage(data);
               }
             );
           }
          }
        );
   */
  public searchByDirection(params: SearchOptions, path: google.maps.DirectionsRoute, clear: boolean = true, callback?: Function): void {
    // mothod only for google provider
    if (this.options?.provider && this.options?.provider !== 'google') {
      console.error('This motod is not implemented for this provider.');
      return;
    }
    if (!params.key) params.key = this.options?.key;
    // channel for statistic
    params.channel = this.#currentHost;

    this.#__otm.service
      .searchByDirection(params, path)
      .then((data: AxiosResponse) => {
        const results: ResponseGeneral = data.data;

        // rimuove la precedente ricerca dalla mappa
        if (clear) {
          this.clear();
        }

        // inserisce i dati su mappa
        if (results.status === 'OK') {
          if ((results.data.results as ResultsGeoJson)?.features) {
            this.#__otm.provider.addData(results.data.results as ResultsGeoJson, undefined, params.disable_focus ? params.disable_focus : undefined);

            // focus dei dati
            if(path && !params.disable_focus) {
              let decodedPath = google.maps.geometry.encoding.decodePath(path['overview_polyline']['points']);

              this.focus(null, decodedPath);
            }
          }
        } else {
          console.error(results.error_message);
        }

        // callback if exist
        if (callback && typeof callback == 'function') {
          callback(results);
        }
      })
      .catch((error: AxiosError) => {
        console.error(error.message);
      });
  }

  /**
   * Callback function called after the distance matrix is done.<br>
   * It adds distance properties to the results of search.
   *
   * @callback searchDistanceMatrixCallback
   * @param {ResponseGeneral} response ResponseGeneral riched by distance properties fields.<br>
   * @param {ResponseGeneral.data.results.features.properties} response.data.results.features.properties
   * Inside object this object, the callback params has:
   * - *"_otm_distance_value"* <br> **type: number** - distance form origin point number<br>
   * - *"_otm_distance_text"* <br> **type: string** - distance form origin point string<br>
   * - *"_otm_duration_value"* <br> **type: number** -  distance in time form origin point numbers<br>
   * - *"_otm_duration_text"* <br> **type: string** - distance in time form origin point string<br>
   *
   */

  /**
   * **ONLY FOR GOOGLE PROVIDER**<br>
   * It finds the distance from the origin point many destinations.
   * It return results in callback.
   * @memberof OnTheMap
   * @param {SearchOptions} params - [search options params]{@link SearchOptions}
   * @param {boolean} [clear=true] - if old search results should be removed
   * @param {searchDistanceMatrixCallback} [callback] - function called at the end of search execution, in it's params we have an array with distance results
   */
  public distanceMatrixSearch(params: SearchOptions, clear: boolean = true, callback?: Function): void {
    let originPosition: google.maps.LatLngLiteral;
    if (params && params.lng && params.lng) {
      originPosition = { lat: params.lat, lng: params.lng };
    } else {
      console.error('Missing lat/lng params');
      return;
    }
    // channel for statistic
    params.channel = this.#currentHost;

    // do the search
    this.search(
      params,
      clear,
      (data: ResponseGeneral) => {
        // if search has results call distanceMatrix
        if (data.status === 'OK' && data.data.total > 0) {
          this.#__otm.provider.distanceMatrixSearch(data, originPosition, (dataOrdered: ResponseGeneral) => {
            if (dataOrdered.status === 'OK') {
              this.#__otm.provider.addData(dataOrdered.data.results as ResultsGeoJson, clear, params.disable_focus ? params.disable_focus : undefined);
            }

            // focus dei dati
            if(!params.disable_focus) {
              let search_position = null;
              if(params["lat"] && params["lng"]) {
                search_position = [ params["lat"], params["lng"] ];
              }

              this.focus(search_position);
            }

            if (callback && typeof callback === 'function') {
              callback(data);
            }
          });
        } else {
          if (callback && typeof callback === 'function') {
            callback(data);
          }
        }
      },
      true // set distanceMatrix search
    );
  }

  /**
   * Function that removes alla markers from a map and empty the [featureCollection]{@link OnTheMap#featureCollection}
   * @memberof OnTheMap
   * @example
   * const otm = new noovle.onthemap({...});
   * otm.search({...});
   * otm.clear();
   */
  public clear(): void {
    this.#__otm.provider.clearMap();
    this.featureCollection = [];
  }

  /**
   * It focus the map on finded data ([featureCollection]{@link OnTheMap#featureCollection})
   * @memberof OnTheMap
   * @example
   * const otm = new noovle.onthemap({...});
   * otm.search({...});
   * otm.focus();
   */
  public focus(search_position?, decodedPath?): void {
    if (this.featureCollection) {
      //@ts-ignore because we can call 2 different types but we dont know which
      this.#__otm.provider.focus(this.featureCollection, search_position, decodedPath);
    }
  }

  /**
   * **Actually works only in Google map provider**<br>
   * Function used to open a specific location detail in infoWindow. The content it specified by otm_id
   * @memberof OnTheMap
   * @param {string} otm_id - otm id to open
   */
  public openInfoWindow(otm_id: string): void {
    if (this.featureCollection) {
      //@ts-ignore because we can call 2 different types but we dont know which
      this.#__otm.provider.openInfoWindow(otm_id, this.featureCollection);
    }
  }

  /**
   * Callback for adding two numbers.
   *
   * @callback infoWindowDomReadyCallback
   * @param {EventObject} event - Event object returned by listener
   * @param {Object} event.detail - The details of point opened
   */

  /**
   * set the listener on infoWindow dom ready and pass the element infos to callback
   * @memberof OnTheMap
   * @param {infoWindowDomReadyCallback}callback - call back info to call, it has EventObject with detail object containing detail data
   */
  public infoWindowDomReady(callback: Function): void {
    window.addEventListener(`${this.options.provider || 'google'}.infoWindowDomReady`, (event) => {
      (event as EventWithDetail).detail && callback((event as EventWithDetail).detail);
    });
  }

  /**
   * Callback function called after the autocomplete search is done and is OK, it has in params the results of search
   *
   * @callback autocompleteCallback
   * @param { google.maps.GeocoderResult | google.maps.places.PlaceResult | object} address - results of the autocomplete search,
   * the object should be for Google Map if there is <a href="https://developers.google.com/maps/documentation/javascript/reference/geocoder#GeocoderResult" target="_blank">one result</a> or <a href="https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult" target="_blank">many results</a>
   * for OpenStreetMap, Kakao and Baiud the object result is { lat: number, lng: number, value: string} where value is the address name
   */
  /**
   * Ceate the autocomplete element and its function in HTML Input. [See examples of implementations](tutorial-set-autocomplete.html)
   * @memberof OnTheMap
   * @param {HTMLInputElement} container - HTML input element where set Autocomplete function
   * @param {google.maps.places.AutocompleteOptions | ProviderParams | KakaoAucompleteOptions | BMap.AutocompleteOptions} options - autocomplete options
   * <br>For provider details:
   * - <a href="https://developers.google.com/maps/documentation/javascript/reference/places-widget#AutocompleteOptions" target="_blank">Google</a>
   * - <a href="https://smeijer.github.io/leaflet-geosearch/providers/openstreetmap" target="_blank">OpenStreetMap</a>
   * - [Kakao]{@link KakaoAucompleteOptions}
   * - <a href="https://mapopen-pub-jsapi.bj.bcebos.com/jsapi/reference/jsapi_reference_3_0.html#a7b51" target="_blank">Baidu</a>
   * @param {autocompleteCallback} callback - function called after Autocomplete
   *
   */
  public setAutocomplete(
    container: HTMLInputElement,
    options?: google.maps.places.AutocompleteOptions | ProviderParams | KakaoAucompleteOptions | BMap.AutocompleteOptions,
    callback?: Function
  ) {
    //@ts-ignore because we can call 2 different types but we dont know which
    this.#__otm.provider.setAutocomplete(container, options, callback);
  }

  /**
   * Callback function called after the geocode search is done, it has in params the results of search.
   *
   * @callback geocodeCallback
   * @param { google.maps.GeocoderResult | KakaoGeocoderResultObject | Array<SearchResult<RawResult>> | BMap.GeocoderResult[] } address - results of the geocode search,
   * the object is for <a href="https://developers.google.com/maps/documentation/javascript/reference/geocoder#GeocoderResult" target="_blank">Google</a>,
   * for [Kakao]{@link KakaoGeocoderResultObject},
   * for <a href="https://smeijer.github.io/leaflet-geosearch/usage#results" target="_blank">OpenStreetMap</a>, and
   * for <a href="https://mapopen-pub-jsapi.bj.bcebos.com/jsapi/reference/jsapi_reference_3_0.html#a7b34" target="_blank">Baidu</a>.
   *
   */
  /**
   * The Geocode function convert address to address object with coordinates and normalized address name and return
   * @memberof OnTheMap
   * @param {string} value - address string to gelocalize
   * @param { ProviderParams | KakaoGeocoderOptions | {city:string}} [options] - Object specific, for <a href="https://smeijer.github.io/leaflet-geosearch/providers/openstreetmap" target="_blank">OpenStreetMap</a>
   * for [Kakao]{@link KakaoGeocoderOptions}
   * for Baidu the object is {city:string}
   * unused for Google provider
   * @param {geocodeCallback} [callback] - function that is trigger after the geocode happen and it has the geocode address object as params
   */
  public geocode(value: string, options?: ProviderParams | KakaoGeocoderOptions, callback?: Function) {
    this.#__otm.provider.geocode(value, options, callback);
  }

  /**
   * return the provider class
   * @memberof OnTheMap
   * @returns {ProviderClass} provider in use google, openstreetmap, kakao, baidu
   */
  public getProvider(): ProviderClass {
    return this.#__otm.provider;
  }

  /**
   * return MarkerClusterer (google map) or kakao.maps.services.MarkerCluster (kakao map) object if cluster not exists return undefined
   * @memberof OnTheMap
   * @returns {MarkerClusterer | kakao.maps.services.MarkerCluster | undefined } MarkerClusterer [MarkerCklusterer]{@link https://googlemaps.github.io/v3-utility-library/classes/_google_markerclustererplus.markerclusterer.html}, [kakao.maps.services.MarkerCluster]{@link https://apis.map.kakao.com/web/documentation/#MarkerClusterer}, if there is not cluster return undefined
   */
  public getMcCluster(): MarkerClusterer | kakao.maps.services.MarkerCluster | undefined {
    return this.#__otm.mcCluster || undefined;
  }
}
