import { ProviderClass } from './../interfaces/ProviderClass';
import { EventWithDetail } from './../interfaces/EventWithDetail';
import { ResultsGeoJson, FeaturesGeoJson, ResponseGeneral } from './../interfaces/Responses';
import OnTheMap from './onthemap';
import {
  KakaoAutocompleteResultObject,
  KakaoAucompleteOptions,
  KakaoGeocoderOptions,
  KakaoGeocoderResultObject,
} from '../interfaces/Kakao';
import { StyleProcessResults } from '../interfaces/OnTheMap';

/**
 * @classdesc This is the provider for Kakao Map. It provides all methods for Kakao Maps functionality
 * @access private
 * @implements ProviderClass
 * @class KakaoMap
 */
export default class KakaoMap implements ProviderClass {
  /**
   * kakao created
   * @type {kakao.maps.Map}
   */
  private _kkMap: kakao.maps.Map;
  /**
   * Options with properties used in functionality
   * For details see {@link OnTheMap} class
   */
  private _otm: OnTheMap;

  /**
   * array of markers
   */
  private _markers: Array<kakao.maps.Marker> = new Array();

  /**
   * event triggered when a place is select in autocomplete list
   */
  private _autocomplete_change: CustomEvent;

  /**
   * marker clusterer object
   */
  private _clusterer: kakao.maps.services.MarkerCluster;

  /**
   * custom event for trigger infoWindowDomReady
   */
  private _infoDomReadyEvent: CustomEvent;

  private fromOpenInfoWindow: boolean = false;

  constructor(otm: OnTheMap) {
    this._otm = otm;
  }

  /**
   * Function non implemented
   * @access private
   */
  distanceMatrixSearch(data: ResponseGeneral, originPosition: google.maps.LatLngLiteral, callback?: Function): void {
    throw new Error('Method not implemented.');
  }

  /**
   * setup the kakao map
   * @access private
   * @param {kakao.maps.Map} map - instance of kakao map
   * @param {boolean} hideOnTheMapLogo
   */
  setupMap(map: kakao.maps.Map, hideOnTheMapLogo: boolean): void {
    this._kkMap = map;
    const control = new kakao.maps.ZoomControl();
    this._kkMap.addControl(control, kakao.maps.ControlPosition.BOTTOMRIGHT);

    if(!hideOnTheMapLogo) {
      const otmLogo = document.createElement('div');

      otmLogo.setAttribute('class', 'otmlogo');

      const otm_a = document.createElement('a');
      const otm_url = document.createTextNode('OnTheMap');

      otm_a.title = 'OnTheMap';
      otm_a.href = 'https://onthemap.io/';
      otm_a.target = '_blank';
      otm_a.style.color = '#000';

      otmLogo.appendChild(otm_a);
      otm_a.appendChild(otm_url);

      this._kkMap.addControl(otmLogo, kakao.maps.ControlPosition.BOTTOMLEFT);

      otmLogo.setAttribute(
        'style',
        otmLogo.getAttribute('style') + ' margin: -1vh 0vh 0vh 1vh; font-size: 1vw;'
      );
    }
  }

  /**
   * add data result to map
   * @access private
   * @param {ResultsGeoJson} data - data results
   * @param {boolean} clear - if clear or not the old data
   * @param {boolean} disableFocus: if true disable focus on search results
   */
  addData(data: ResultsGeoJson, clear: boolean = true, disableFocus: boolean = false): void {
    const features: FeaturesGeoJson[] = (data.features as FeaturesGeoJson[]) || [];

    // delete old markers in map
    if (clear) {
      this.clearMap();
    }

    // Check coincident points - Start
    let checkCoincidentPoints = {};
    let coincidentPoints = {};

    features.forEach(function(feature) {
      const position: kakao.maps.LatLng = new kakao.maps.LatLng(feature.geometry?.coordinates[1], feature.geometry?.coordinates[0]);
      const key = position.getLat() + ',' + position.getLng();

      if(!checkCoincidentPoints[key]) {
        checkCoincidentPoints[key] = {};
      }

      // Object.assign per creare una copia di "feature" e non inserire l'oggetto "coincidentPoint" in loop dentro "coincidentPoint"
      checkCoincidentPoints[key][feature["properties"]["otm_id"]] = Object.assign({}, feature);
    });

    for(let p = 0; p < Object.keys(checkCoincidentPoints).length; p++) {
      if(Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]).length > 1) {
        // console.log('Coincident points');
        // console.log(Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]));

        for(let a = 0; a < Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]).length; a++) {
          let key = Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]])[a];
          let cpObj = checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]];

          coincidentPoints[key] = cpObj;
        }
      } else {
        // console.log('Single point');
        // console.log(Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]));
      }
    }

    // console.log('coincidentPoints');
    // console.log(coincidentPoints);

    features.forEach(function(feature) {
      if(feature["properties"]["otm_id"] in coincidentPoints) {
        // feature["coincidentPoint"] contiene un oggetto con:
        // - chiave: otm_id
        // - valore: feature (geometry, properties, type)
        // in cui sono presenti tutti i punti coincidenti con quello corrente (compreso il punto stesso)
        feature["coincidentPoint"] = coincidentPoints[feature["properties"]["otm_id"]];
      } else {
        feature["coincidentPoint"] = null;
      }
    });
    // Check coincident points - End

    // add new markers in map
    features.forEach((feature: FeaturesGeoJson, index: number) => {
      const position: kakao.maps.LatLng = new kakao.maps.LatLng(feature.geometry?.coordinates[1], feature.geometry?.coordinates[0]);
      const { otm_id } = feature.properties;

      // check if styleProcess exist
      if (this._otm?.options?.styleProcess) {
        const iconObj: StyleProcessResults = this._otm.options.styleProcess(feature, index);
        // create image property from icon property and delete it
        (iconObj.default['image'] as kakao.maps.MarkerImage) = iconObj.default['icon'] as kakao.maps.MarkerImage;
        delete iconObj.default['icon'];
        const markerOpt: any = { ...iconObj.default, position: position };

        // styleProcess must have default marker options
        if (iconObj.default) {
          const marker: kakao.maps.Marker & { otm_id?: string; feature?: FeaturesGeoJson } = Object.assign(
            new kakao.maps.Marker(markerOpt),
            { otm_id, feature }
          );

          /** add over state for marker */
          if(iconObj.hover) {
            kakao.maps.event.addListener(marker, 'mouseover', () => {
              if(marker['otm_currentIconStyle'] !== 'active') {
                marker.setImage(iconObj.hover);

                // Add current style information to marker
                marker['otm_currentIconStyle'] = 'hover';
              }
            });

            kakao.maps.event.addListener(marker, 'mouseout', () => {
              //@ts-ignore the markerOptions type is not defined so .image is error
              if(marker['otm_currentIconStyle'] !== 'active') {
                marker.setImage(iconObj.default.image as kakao.maps.MarkerImage);

                // Add current style information to marker
                marker['otm_currentIconStyle'] = 'default';
              }
            });
          }

          this._markers.push(marker);

          // Add marker overlay to current result as "markerOverlay" (with "geometry" and "properties")
          feature['markerOverlay'] = marker;

          kakao.maps.event.addListener(marker, 'click', () => {
            this.setInfoWindowContent(feature, marker);
          });
        }
        // no default marker options
        else {
          console.error('KakaoMap addData: no default marker options provided');
          return;
        }
      }
    });

    // if marker option is setted, add markercluster
    if (this._otm?.options?.mcOptions && Object.keys(this._otm?.options?.mcOptions).length > 0) {
      const markerClusterOptions: kakao.maps.services.MarkerClusterOptions = Object.assign(
        <kakao.maps.services.MarkerClusterOptions>this._otm.options.mcOptions,
        { map: this._kkMap }
      );
      // add cluster
      // @ts-ignore
      this._clusterer = new kakao.maps.MarkerClusterer(markerClusterOptions);
      // set the cluster object in onthemap class
      // @ts-ignore
      this._otm.mcCluster = this._clusterer;

      // Nel caso di punti coincidenti viene aggiunto solamente un marker all'intero del MarkerCluster
      let markerOnMap: object = {};
      let markersToCluster: Array<any> = [];
      let markerPosition: string = '';

      for(var m = 0; m < this._markers.length; m++) {
        const position: kakao.maps.LatLng = new kakao.maps.LatLng(this._markers[m]['feature']['geometry']['coordinates'][1], this._markers[m]['feature']['geometry']['coordinates'][0]);
        const markerPosition = position.getLat() + ',' + position.getLng();

        if(markerOnMap[markerPosition] !== true) {
          markersToCluster.push(this._markers[m]);
          markerOnMap[markerPosition] = true;
        }
      }

      // @ts-ignore
      this._clusterer.addMarkers(markersToCluster);
    }
    // if there are not config for cluster it add only markers
    else {
      this._markers.forEach((marker: kakao.maps.Marker) => {
        marker.setMap(this._kkMap);
      });
    }

    this._otm.featureCollection = features;
  }

  /**
   * Set the content of an infoWindow alias balloon and open it
   * @access private
   * @param {FeaturesGeoJson} feat - feature alias one item result
   * @param {kakao.maps.Marker} marker - marker
   */
  setInfoWindowContent?(feat: FeaturesGeoJson, marker: kakao.maps.Marker): void {
    const position: kakao.maps.LatLng = new kakao.maps.LatLng(feat.geometry?.coordinates[1], feat.geometry?.coordinates[0]);
    let content: string;

    // Se è stata chiamata la "openInfoWindow" viene aperto direttamente il balloon del risultato corrispondente
    // all'otm_id indicato
    if(this.fromOpenInfoWindow) {
      let tmp = Object.assign({}, feat);

      if(tmp['coincidentPoint']) {
        tmp['coincidentPoint'] = null;
      }

      content = this._otm?.options?.infoWindowProcess(tmp) || '';

      this.fromOpenInfoWindow = false;
    } else {
      content = this._otm?.options?.infoWindowProcess(feat) || '';
    }

    // add infowindow
    const infowindow = new kakao.maps.InfoWindow({
      position: position,
      content: content,
      removable: true,
    });

    /** dispatch the infoWoindowDomReady event */
    this._infoDomReadyEvent = new CustomEvent('kakao.infoWindowDomReady', { detail: feat });
    window.dispatchEvent(this._infoDomReadyEvent);

    this.closeInfoWindow();

    if (this._otm.options.autoOpenInfoWindow) {

      // apre infowindow solo se ha contenuto
      if(content) {
        infowindow.open(this._kkMap, marker);
      }

      this._otm.infoWindowActive = infowindow;

      // TODO
      // Nel caso in cui in una futura versione delle API Kakao Map venga esposto un listener per intercettare
      // la chiusura dell'infoWindow: aggiungere la gestione dello stato "active" per il marker selezionato
      /*
      const iconObj: StyleProcessResults = this._otm.options.styleProcess(feat);

      if(iconObj.active) {
        marker.setImage(iconObj.active);

        // Add current style information to marker
        marker['otm_currentIconStyle'] = 'active';

        // Close infoWindow on map click
        // kakao.maps.event.addListener(this._kkMap, 'click', () => {
        //   marker.setImage(iconObj.default.icon as kakao.maps.MarkerImage);

        //   // Add current style information to marker
        //   marker['otm_currentIconStyle'] = 'default';

        //   this.closeInfoWindow();
        // });
      }
      */
    }
  }

  /**
   * clear the map removing markers and cluster
   * @access private
   */
  clearMap(): void {
    this._markers.forEach((marker: kakao.maps.Marker) => {
      marker.setMap(null);
    });
    if (this._clusterer) {
      // @ts-ignore
      this._clusterer.removeMarkers(this._markers);
    }
    this._markers = [];

    this.closeInfoWindow();
  }

  /**
   * close the infoWindowActive
   * @access private
   */
  private closeInfoWindow(): void {
    if (this._otm.infoWindowActive) {
      (this._otm.infoWindowActive as kakao.maps.InfoWindow).close();
      this._otm.infoWindowActive = undefined;
    }
  }

  /**
   * focus map on data
   * @access private
   * @param {FeaturesGeoJson[]}featureCollection
   */
  focus(featureCollection: FeaturesGeoJson[], search_position?): void {
    if (!!featureCollection.length) {
      const bounds: kakao.maps.LatLngBounds = new kakao.maps.LatLngBounds();

      featureCollection.forEach((feature) => {
        const latlng: kakao.maps.LatLng = new kakao.maps.LatLng(feature.geometry?.coordinates[1], feature.geometry?.coordinates[0]);
        bounds.extend(latlng);
      });

      // estensione bounds all'indirizzo ricercato
      if(search_position && search_position.length == 2) {
        let search_position_latlng =  new kakao.maps.LatLng(search_position[0], search_position[1]);
        bounds.extend(search_position_latlng);
      }

      this._kkMap.setBounds(bounds);
    }
  }

  /**
   * open window of specific point
   * @access private
   * @param {string} otm_id - otm id
   * @param {FeaturesGeoJson[]} featureCollection - results collections
   */
  openInfoWindow(otm_id: string, featureCollection: FeaturesGeoJson[]): void {
    this.fromOpenInfoWindow = true;

    const featToOpen: FeaturesGeoJson[] = featureCollection.filter((feat: FeaturesGeoJson) => {
      return feat.properties?.otm_id === otm_id;
    });
    const markerToOpen: Array<kakao.maps.Marker & { otm_id?: string }> = this._markers.filter(
      (marker: kakao.maps.Marker & { otm_id?: string }) => {
        return marker.otm_id === otm_id;
      }
    );

    if (markerToOpen.length === 1 && featToOpen.length === 1) {
      this.setInfoWindowContent(featToOpen[0], markerToOpen[0]);
    }
  }

  /**
   * set the autocomplete service
   * @access private
   * @param {HTMLInputElement} container - the input where attach autocomplete
   * @param {KakaoAucompleteOptions} options - autocomplete options
   * @param {Function} callback - callback function
   */
  setAutocomplete(container: HTMLInputElement, options: KakaoAucompleteOptions, callback: Function): void {
    // delay for search
    let delay: ReturnType<typeof setTimeout>;
    // the id of div results
    const resContainerId: string = options?.resultId ? options.resultId : 'kakaoAutocompleteResults';
    const searchMinLenght: number = options?.searchMinLenght ? options.searchMinLenght : 3;

    // create wrapper around input and reults
    if (!document.querySelector('#autocomplete__wrapper')) {
      // create wrapper container
      const wrapper: HTMLDivElement = document.createElement('div');
      wrapper.setAttribute('id', 'autocomplete__wrapper');
      wrapper.style.position = 'relative';

      // insert wrapper before el in the DOM tree
      container.parentNode.insertBefore(wrapper, container);

      // move el into wrapper
      wrapper.appendChild(container);
    }

    // on focus in try to search results
    container.addEventListener('focusin', () => {
      this.createAutocompleteResults(container, resContainerId, searchMinLenght);
    });

    // on focus uout remove the results
    container.addEventListener('focusout', () => {
      // delay for fix problem of focusin that do not fires the onclick in choose address
      setTimeout(() => {
        this.removeAutocompleteResults(resContainerId);
      }, 100);
    });

    // on key press check if string is > 3 and try to search results
    container.addEventListener('keyup', (event) => {
      if (delay) {
        clearTimeout(delay);
      }
      // set a delay
      delay = setTimeout(() => {
        // security checks
        if (event.isComposing || event.key.charCodeAt(229)) {
          return;
        }
        this.createAutocompleteResults(container, resContainerId, searchMinLenght);
      }, 250);
    });

    if (callback && typeof callback == 'function') {
      // check if event listener is added
      if (!document.body.getAttribute('autocomplete_change')) {
        window.addEventListener('autocomplete_change', (event) => {
          // console.log('[adress choosen in autocomplete]', JSON.stringify(event.detail));

          if (callback && typeof callback == 'function') {
            callback((event as EventWithDetail).detail);
          }
        });

        // add attribute to know if listener is added
        document.body.setAttribute('autocomplete_change', 'true');
      }
    }
  }

  /**
   * Create the autocomplete results list
   * @access private
   * @param {HTMLInputElement} container - The autocomplete HTML element
   * @param {Astring} resContainerId - the id of ul list results
   */
  private createAutocompleteResults(container: HTMLInputElement, resContainerId: string, searchMinLenght: number): void {
    const placeService = new kakao.maps.services.Places(this._kkMap);
    const valueTyped: string = container.value;
    const resultContainer: HTMLUListElement = document.createElement('ul');
    resultContainer.setAttribute('id', resContainerId);

    // do search only if value is > then min value lenght
    if (container.value.trim().length > searchMinLenght) {
      placeService.keywordSearch(
        valueTyped,
        (data: Array<KakaoAutocompleteResultObject>, status: string, pagination: kakao.maps.services.Pagination) => {
          if (status === kakao.maps.services.Status.ERROR) {
            console.error('Error on search');
            // if (callback) callback(status); @TODO MANAGE ERROR
            return;
          }
          if (status === kakao.maps.services.Status.ZERO_RESULT) {
            // console.error('No data found for this search');
            document.querySelector(`#${resContainerId}`) && document.querySelector(`#${resContainerId}`).remove();
            // if (callback) callback(status); @TODO MANAGE ZERO RESULT
            return;
          }
          if (status === kakao.maps.services.Status.OK) {
            // add style
            const style: string = `
                              #${resContainerId}{
                                position:absolute;
                                left:0;
                                top:${container.offsetHeight}px;
                                margin: 0;
                                padding: 0;
                                list-style: none;
                                border: 1px solid #CDCDCD;
                                background-color:rgba(255,255,255, 0.9);
                                z-index:99999;
                              }
                              #${resContainerId} .kakao__ItemResultAutocomplete {
                                cursor: pointer;
                                padding: 3px 10px;
                              }
                              #${resContainerId} .kakao__ItemResultAutocomplete:hover {
                                background-color: #CDCDCD;
                              }
                              `;
            // add the style to the page
            document.head.insertAdjacentHTML('beforeend', `<style>${style}</style>`);

            // remove the old autocomplete if exists
            if (document.querySelector(`#${resContainerId}`) && document.querySelector(`#${resContainerId}`).hasChildNodes()) {
              document.querySelector(`#${resContainerId}`).remove();
            }

            // create the li elements foreach results of search
            data.forEach((result: KakaoAutocompleteResultObject) => {
              // highlight the serached string
              let label: string = result.road_address_name || result.address_name;
              const reg = new RegExp('(' + valueTyped + ')', 'gi');
              label = label.replace(reg, '<b class="searched_string_highlight">$1</b>');

              // create the li element
              let listResult: HTMLLIElement = document.createElement('li');
              listResult.innerHTML = label;
              listResult.setAttribute('data-lat', result.y as string);
              listResult.setAttribute('data-lng', result.x as string);
              listResult.setAttribute('class', 'kakao__ItemResultAutocomplete');

              // add the click listener for selected element
              listResult.addEventListener('click', (event: Event) => {
                const labelSelected: string = this.selectAutocompleteResult(event, resContainerId);
                container.value = labelSelected;
              });
              // add the li list to the ul element
              resultContainer.appendChild(listResult);
            });

            // add the ul list if has li elements inside and add blur listener
            if (resultContainer.hasChildNodes()) {
              container.insertAdjacentElement('afterend', resultContainer);
            }
          }
        }
      );
    } else {
      this.removeAutocompleteResults(resContainerId);
    }
  }

  /**
   * manage the selected result of the autocomplete
   * @access private
   * @param {Event} event - event click
   * @param {string} resContainerId - the id of ul list results
   */
  private selectAutocompleteResult(event: Event, resContainerId: string): string {
    const elSelected: HTMLElement = <HTMLElement>event.target;
    const labelSelected: string = elSelected.textContent;
    const position: kakao.maps.LatLng =
      elSelected instanceof HTMLLIElement
        ? new kakao.maps.LatLng(parseFloat(elSelected.dataset.lat), parseFloat(elSelected.dataset.lng))
        : new kakao.maps.LatLng(parseFloat(elSelected.parentElement.dataset.lat), parseFloat(elSelected.parentElement.dataset.lng));
    // if not exist
    this._autocomplete_change = new CustomEvent('autocomplete_change', {
      detail: { value: labelSelected, lat: position.getLat(), lng: position.getLng() },
    });
    window.dispatchEvent(this._autocomplete_change);
    this.removeAutocompleteResults(resContainerId);

    return labelSelected;
  }

  /**
   * remove the results list
   * @access private
   * @param {string} resContainerId - the id of ul list results
   */
  private removeAutocompleteResults(resContainerId: string): void {
    const resContainer: HTMLUListElement = document.querySelector(`#${resContainerId}`);
    if (resContainer) resContainer.remove();
  }

  /**
   * Geocode the the address (value) in input
   * @access private
   * @param {string} value - Address to geolocalize
   * @param {KakaoGeocoderOptions} options - options for geocode addressSearch [see](https://apis.map.kakao.com/web/documentation/#services_Geocoder_addressSearch)
   * @param {Function} callback - function callback
   */
  geocode(value: string, options?: KakaoGeocoderOptions, callback?: Function): void {
    const geocoder = new kakao.maps.services.Geocoder();
    let output: Array<KakaoGeocoderResultObject> = new Array();

    geocoder.addressSearch(
      value,
      (result: Array<KakaoGeocoderResultObject>, status: kakao.maps.services.Status) => {
        if (status === kakao.maps.services.Status.OK) {
          output = result;
        } else {
          console.error('Address not found');
        }

        if (callback && typeof callback == 'function') {
          callback(output);
        }
      },
      options
    );
  }
}
