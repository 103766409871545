import { ProviderParams } from 'leaflet-geosearch/dist/providers/provider';
import { StyleProcessResults } from '../interfaces/OnTheMap';
import { ProviderClass } from '../interfaces/ProviderClass';
import { MarkerCluster } from '@h21-map/baidu-markercluster';
import { ResultsGeoJson, FeaturesGeoJson, ResponseGeneral } from '../interfaces/Responses';
import OnTheMap from './onthemap';

export class BaiduMap implements ProviderClass {
  /**
   * Markercluster object {@link MarkerCluster}
  */
  private _cluster: MarkerCluster = null;

  /**
   * Array of BMap.Marker with property otm_id used to get the tighe marker when we want
   * open a specific infoWindow
   */
  private _markers: Array<BMap.Marker & { otm_id?: string }> = [];

  /**
   * Array of BMap.Marker (used to manage the status of markers in case of coincident points)
  */
  private _coincidentPointMarkers: Array<BMap.Marker & { otm_id?: string }> = [];

  /**
   * [Baidu map object] {@link BMap.Map}
   */
  private _bmap: BMap.Map;

  /**
   * Options with properties used in functionality
   * For details see {@link OnTheMap} class
   */
  private _otm: OnTheMap;

  /**
   * custom event for trigger infoWindowDomReady
   */
  private _infoDomReadyEvent: CustomEvent;

  /**
   * Marker attualmente nello stato "active"
   */
  private currentActiveMarker: BMap.Marker;

  private fromOpenInfoWindow: boolean = false;

  constructor(otm: OnTheMap) {
    this._otm = otm;
  }

  /**
   * Set map and add controls and copyright
   * @access private
   * @param {BMap.Map} map
   * @param {boolean} hideOnTheMapLogo
   */
  setupMap(map: BMap.Map, hideOnTheMapLogo: boolean): void {
    map.enableScrollWheelZoom(); // enable mouse zoom
    // add navigation zoom control
    map.addControl(
      new BMap.NavigationControl({
        anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
        offset: new BMap.Size(0, 20),
      })
    );

    // add copyright
    if(!hideOnTheMapLogo) {
      const copyControl: BMap.CopyrightControl = new BMap.CopyrightControl({
        anchor: BMAP_ANCHOR_BOTTOM_LEFT,
        offset: new BMap.Size(85, 20),
      });
      map.addControl(copyControl); //add control object
      const bs: BMap.Bounds = map.getBounds(); //get the bounds of the map
      copyControl.addCopyright({
        id: 1,
        content:
          "<a href='https://onthemap.io/' target='_blank' style='font-size:11px;color:#000;padding-left: 5px; border-left: 1px solid #000; text-decoration: none;'>OnTheMap</a>",
        bounds: bs,
      });
    }

    this._bmap = map;
  }

  /**
   * add tada results to map
   * @access private
   * @param {ResultsGeoJson} data
   * @param {boolean} clear
   * @param {boolean} disableFocus: if true disable focus on search results
   */
  addData(data: ResultsGeoJson, clear: boolean, disableFocus: boolean): void {
    // throw new Error('Method not implemented.');
    const featArray: FeaturesGeoJson[] = (data.features as FeaturesGeoJson[]) || [];
    if (clear) {
      this.clearMap();
    }

    // Check coincident points - Start
    let checkCoincidentPoints = {};
    let coincidentPoints = {};

    featArray.forEach(function(feature) {
      const position: BMap.Point = new BMap.Point(feature.geometry.coordinates[0], feature.geometry.coordinates[1]);
      const tmp_key = position.lng + ',' + position.lat;

      if(!checkCoincidentPoints[tmp_key]) {
        checkCoincidentPoints[tmp_key] = {};
      }

      // Object.assign per creare una copia di "feature" e non inserire l'oggetto "coincidentPoint" in loop dentro "coincidentPoint"
      checkCoincidentPoints[tmp_key][feature["properties"]["otm_id"]] = Object.assign({}, feature);
    });

    for(let p = 0; p < Object.keys(checkCoincidentPoints).length; p++) {
      if(Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]).length > 1) {
        // console.log('Coincident points');
        // console.log(Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]));

        for(let a = 0; a < Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]).length; a++) {
          let key = Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]])[a];
          let cpObj = checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]];

          coincidentPoints[key] = cpObj;
        }
      } else {
        // console.log('Single point');
        // console.log(Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]));
      }
    }

    // console.log('coincidentPoints');
    // console.log(coincidentPoints);

    featArray.forEach(function(feature) {
      if(feature["properties"]["otm_id"] in coincidentPoints) {
        // feature["coincidentPoint"] contiene un oggetto con:
        // - chiave: otm_id
        // - valore: feature (geometry, properties, type)
        // in cui sono presenti tutti i punti coincidenti con quello corrente (compreso il punto stesso)
        feature["coincidentPoint"] = coincidentPoints[feature["properties"]["otm_id"]];
      } else {
        feature["coincidentPoint"] = null;
      }
    });
    // Check coincident points - End

    const bounds: BMap.Point[] = [];

    featArray.forEach((feature: FeaturesGeoJson, index: number) => {
      const type: string = feature.geometry.type;
      const position: BMap.Point = new BMap.Point(feature.geometry.coordinates[0], feature.geometry.coordinates[1]);

      if (type === 'Point') {
        // add process style for custom icon
        if (this._otm?.options?.styleProcess) {
          const iconsObj: StyleProcessResults = this._otm.options.styleProcess(feature, index);

          if (iconsObj.default as BMap.MarkerOptions) {
            const marker: BMap.Marker & { otm_id?: string; feature?: FeaturesGeoJson } = Object.assign(
              new BMap.Marker(position, iconsObj.default as BMap.MarkerOptions),
              {
                otm_id: feature.properties.otm_id,
                feature,
              }
            );

            // Add styleProcess object to manage icon images by status
            marker['otm_styleProcessObject'] = iconsObj;

            if(iconsObj.hover) {
              marker.addEventListener('mouseover', () => {
                if(marker['otm_currentIconStyle'] !== 'active') {
                  marker.setIcon(iconsObj.hover as BMap.Icon);

                  // Add current style information to marker
                  marker['otm_currentIconStyle'] = 'hover';
                }
              });

              marker.addEventListener('mouseout', () => {
                if(marker['otm_currentIconStyle'] !== 'active') {
                  marker.setIcon((iconsObj.default as BMap.MarkerOptions).icon as BMap.Icon);

                  // Add current style information to marker
                  marker['otm_currentIconStyle'] = 'default';
                }
              });
            }

            // MarkerCluster or Single markers / coincident points
            marker.addEventListener('click', () => {
              // Reset "default" status of the previous selected marker
              if(!this.currentActiveMarker) {
                this.currentActiveMarker = marker;
              } else {
                if(this._coincidentPointMarkers.length > 0) {
                  for(let cm = 0; cm < this._coincidentPointMarkers.length; cm++) {
                    this._coincidentPointMarkers[cm].setIcon((this._coincidentPointMarkers[cm]['otm_styleProcessObject'].default as BMap.MarkerOptions).icon as BMap.Icon);

                    // Add current style information to marker
                    this._coincidentPointMarkers[cm]['otm_currentIconStyle'] = 'default';
                  }

                  this._coincidentPointMarkers = [];

                  // Update the current active marker
                  this.currentActiveMarker = marker;
                } else {
                  this.currentActiveMarker.setIcon((this.currentActiveMarker['otm_styleProcessObject'].default as BMap.MarkerOptions).icon as BMap.Icon);

                  // Add current style information to marker
                  this.currentActiveMarker['otm_currentIconStyle'] = 'default';

                  // Update the current active marker
                  this.currentActiveMarker = marker;
                }
              }

              if(this._otm?.options?.autoOpenInfoWindow) {
                if(this._otm?.options?.infoWindowProcess) {
                  if(feature["coincidentPoint"]) {
                    // Coincident points (show the list of all results in this position)
                    this.setInfoWindowContent(feature, feature["coincidentPoint"] as object);
                  } else {
                    this.setInfoWindowContent(feature);
                  }
                }
              } else {
                this.setIconStyle(feature);
              }
            });

            // add the marker to map
            this._bmap.addOverlay(marker);
            // add the markers to array of markers
            this._markers.push(marker);

            // Add marker overlay to current result as "markerOverlay" (with "geometry" and "properties")
            feature['markerOverlay'] = marker;

            // add position point to array
            bounds.push(position);
          } else {
            console.error('Baidu map addData: no default marker state provided');
            return;
          }
        }
      }
    });

    // Gestione MarkeCluster
    if(this.hasMarkerCluster()) {
      if(!this._cluster) {
        this._cluster = new MarkerCluster(this._bmap, this._otm.options.mcOptions);
        // Set the cluster object in onthemap class
        this._otm.mcCluster = this._cluster;
      }

      // Nel caso di punti coincidenti viene aggiunto solamente un marker all'intero del MarkerCluster
      let markerOnMap: object = {};
      let markersToCluster: Array<any> = [];
      let markerPosition: string = '';

      for(var m = 0; m < this._markers.length; m++) {
        const position: BMap.Point = new BMap.Point(this._markers[m]['feature']['geometry']['coordinates'][0], this._markers[m]['feature']['geometry']['coordinates'][1]);
        const markerPosition = position.lng + ',' + position.lat;

        if(markerOnMap[markerPosition] !== true) {
          markersToCluster.push(this._markers[m]);
          markerOnMap[markerPosition] = true;
        } else {
          // Il marker del punto coincidente a quello già presente su mappa viene nascosto
          // in modo da evitare che sia presente sia nel cluster, sia fuori dallo stesso
          this._markers[m].hide();
        }
      }

      /** Add markers to MarkerCluster */
      this._cluster.addMarkers(markersToCluster);
    }

    if(!disableFocus) {
      // set the viewport
      this._bmap.setViewport(bounds);
    }

    // set the featurecolletion of onthemap,
    // @todo this should be REVIEWED
    this._otm.featureCollection = featArray;
  }

  /**
   * set the content of the infowindow
   * @access private
   * @param {FeaturesGeoJson} feat - result object
   */
  setInfoWindowContent(feat: FeaturesGeoJson, coincidentPoints: object): void {
    let content: string;

    // Se è stata chiamata la "openInfoWindow" viene aperto direttamente il balloon del risultato corrispondente
    // all'otm_id indicato
    if(this.fromOpenInfoWindow) {
      let tmp = Object.assign({}, feat);

      if(tmp['coincidentPoint']) {
        tmp['coincidentPoint'] = null;
      }

      content = this._otm?.options?.infoWindowProcess(tmp) || '';

      this.fromOpenInfoWindow = false;
    } else {
      content = this._otm?.options?.infoWindowProcess(feat) || '';
    }

    const infoW: BMap.InfoWindow = new BMap.InfoWindow(content);
    const position: BMap.Point = new BMap.Point(feat.geometry.coordinates[0], feat.geometry.coordinates[1]);

    // if there is an infowindow open somewhere
    this.closeInfoWindow();

    if (this._otm.options.autoOpenInfoWindow) {

      // apre infowindow solo se ha contenuto
      if(content) {
        this._bmap.openInfoWindow(infoW, new BMap.Point(position.lng, position.lat));
      }

      // set infoWindowActive
      this._otm.infoWindowActive = infoW;

      const iconsObj: StyleProcessResults = this._otm.options.styleProcess(feat);

      /** manage active style on marker */
      if(iconsObj.active) {
        const markerToManage: BMap.Marker[] = this._markers.filter(
          (marker: BMap.Marker & { otm_id?: string; feature?: FeaturesGeoJson }) => {
            const id = marker.otm_id;

            if(coincidentPoints) {
              if(id in coincidentPoints) {
                for(let i in coincidentPoints) {
                  return true;
                }
              } else {
                return false;
              }
            } else {
              return id === feat.properties?.otm_id;
            }
          }
        );

        /*
        if(markerToManage.length > 1) {
          console.error('Baidu map setInfoWindowContent: no marker to activate');

          return;
        } else {
          markerToManage[0].setIcon(iconsObj.active as BMap.Icon);

          // Add current style information to marker
          markerToManage[0]['otm_currentIconStyle'] = 'active';

          infoW.addEventListener('close', () => {
            markerToManage[0].setIcon((iconsObj.default as BMap.MarkerOptions).icon as BMap.Icon);

            // Add current style information to marker
            markerToManage[0]['otm_currentIconStyle'] = 'default';

            this._otm.infoWindowActive = undefined;
            infoW.removeEventListener('close', () => {});
          });
        }
        */

        if(coincidentPoints) {
          this._coincidentPointMarkers = markerToManage;
        }

        for(let m = 0; m < markerToManage.length; m++) {
          markerToManage[m].setIcon(iconsObj.active as BMap.Icon);

          // Add current style information to marker
          markerToManage[m]['otm_currentIconStyle'] = 'active';

          infoW.addEventListener('close', () => {
              if(this._coincidentPointMarkers.length > 0) {
                for(let cm = 0; cm < this._coincidentPointMarkers.length; cm++) {
                  this._coincidentPointMarkers[cm].setIcon((iconsObj.default as BMap.MarkerOptions).icon as BMap.Icon);

                  // Add current style information to marker
                  this._coincidentPointMarkers[cm]['otm_currentIconStyle'] = 'default';
                }

                this._coincidentPointMarkers = [];
                this._otm.infoWindowActive = undefined;
                infoW.removeEventListener('close', () => {});
              } else {
                markerToManage[m].setIcon((iconsObj.default as BMap.MarkerOptions).icon as BMap.Icon);

                // Add current style information to marker
                markerToManage[m]['otm_currentIconStyle'] = 'default';

                this._otm.infoWindowActive = undefined;
                infoW.removeEventListener('close', () => {});
              }
            }
          );
        }
      }
    }

    /** dispatch the infoWoindowDomReady event */
    this._infoDomReadyEvent = new CustomEvent('baidu.infoWindowDomReady', { detail: feat });
    window.dispatchEvent(this._infoDomReadyEvent);
  }

  /**
   * Set the "active" icon style of selected marker
   * @access private
   * @param {FeaturesGeoJson} feat - result object
   */
  private setIconStyle(feat: FeaturesGeoJson): void {
    const iconsObj: StyleProcessResults = this._otm.options.styleProcess(feat);

    if(iconsObj.active) {
      const markerToManage: BMap.Marker[] = this._markers.filter(
        (marker: BMap.Marker & { otm_id?: string; feature?: FeaturesGeoJson }) => {
          return marker.otm_id === feat.properties?.otm_id;
        }
      );

      if(markerToManage.length > 1) {
        console.error('setIconStyle: error on selecting the marker to activate');

        return;
      } else {
        markerToManage[0].setIcon(iconsObj.active as BMap.Icon);

        // Add current style information to marker
        markerToManage[0]['otm_currentIconStyle'] = 'active';
      }
    }
  }

  /**
   * clear map from results
   * @access private
   */
  clearMap(): void {
    this._bmap.clearOverlays();
    this._cluster && this._cluster.clearMarkers();
    this._markers = [];
    this._coincidentPointMarkers = [];
    this.closeInfoWindow();
  }

  /**
   * focus map on results
   * @access private
   * @param {FeaturesGeoJson[]} featureCollection - results collection
   */
  focus(featureCollection: FeaturesGeoJson[], search_position?): void {
    const bounds: BMap.Point[] = [];

    featureCollection.forEach((feature) => {
      const latlng: BMap.Point = new BMap.Point(feature.geometry?.coordinates[0], feature.geometry?.coordinates[1]);
      bounds.push(latlng);
    });

    // estensione bounds all'indirizzo ricercato
    if(search_position && search_position.length == 2) {
      let search_position_latlng =  new BMap.Point(search_position[1], search_position[0]);
      bounds.push(search_position_latlng);
    }

    this._bmap.setViewport(bounds);
  }

  /**
   * open info window of specific id
   * @access private
   * @param {string} otm_id - id otm
   * @param {FeaturesGeoJson[]} featureCollection - collections of results
   */
  openInfoWindow(otm_id: string, featureCollection: FeaturesGeoJson[]): void {
    this.fromOpenInfoWindow = true;

    const featToOpen: FeaturesGeoJson[] = featureCollection.filter((feat: FeaturesGeoJson) => {
      return feat.properties?.otm_id === otm_id;
    });
    const markerToOpen: Array<BMap.Marker & { otm_id?: string }> = this._markers.filter((marker: BMap.Marker & { otm_id?: string }) => {
      return marker.otm_id === otm_id;
    });

    if (markerToOpen.length === 1 && featToOpen.length === 1) {
      if(featToOpen[0]["coincidentPoint"]) {
        // In order to manage all coincident point marker's status at the same time
        this.setInfoWindowContent(featToOpen[0], featToOpen[0]["coincidentPoint"] as object);
      } else {
        this.setInfoWindowContent(featToOpen[0]);
      }
    }
  }

  /**
   * close the infoWindowActive
   * @access private
   */
  private closeInfoWindow(): void {
    if (this._otm.infoWindowActive) {
      this._bmap.closeInfoWindow();
      this._otm.infoWindowActive = undefined;
    }
  }

  setAutocomplete(container: HTMLInputElement, options: BMap.AutocompleteOptions, callback: Function): void {
    const autocomplete: BMap.Autocomplete = new BMap.Autocomplete({
      input: container,
      location: this._bmap,
      onSearchComplete: (result: BMap.AutocompleteResult) => {
        if (result) {
          autocomplete.show();
        } else {
          autocomplete.hide();
        }
      },
    });

    autocomplete.onconfirm = (event: { type: string; item: { index: number; value: BMap.AutocompleteResultPoi }; target: string }) => {
      // console.log('confirm event', event);
      const value: BMap.AutocompleteResultPoi = event?.item?.value;
      const completeAddress = `${value.province || ''}${value.City || ''}${value.district || ''}${value.street || ''}${
        value.business || ''
      }`; // fix issu when some attributes in value are empty

      let output: { value: string; lat: number; lng: number };
      // console.log('complete address', completeAddress);
      // console.log('value city', value.city);

      const geocoder: BMap.Geocoder = new BMap.Geocoder();
      geocoder.getPoint(
        completeAddress,
        (point: BMap.Point) => {
          if (point) {
            output = {
              value: completeAddress,
              lat: point.lat,
              lng: point.lng,
            };
          } else {
            console.error('Wrong address georeferencing');
          }

          if (callback && typeof callback == 'function') {
            callback(output);
          }
        },
        value.City
      );
    };
  }

  distanceMatrixSearch(data: ResponseGeneral, originPosition: google.maps.LatLngLiteral, callback?: Function): void {
    throw new Error('Method not implemented.');
  }

  /**
   * Geocode from partial address to address complete
   * @private
   * @param value
   * @param {object} [options] - object where pass options
   * @param {string} [options.city = ''] - city name, default is ''
   * @param callback
   */
  geocode(value: string, options?: { city?: string }, callback?: Function): void {
    const geocoder: BMap.Geocoder = new BMap.Geocoder();
    const city: string = (options?.city as string) ? (options.city as string) : '';

    let output: BMap.GeocoderResult[] = new Array();

    geocoder.getPoint(
      value,
      (point: BMap.Point) => {
        if (point) {
          // if point is found
          geocoder.getLocation(point, (address: BMap.GeocoderResult) => {
            if (address) {
              output.push(address);
            }
            if (callback && typeof callback == 'function') {
              callback(output);
            }
          });
        } else {
          console.error('Address not found');
          if (callback && typeof callback == 'function') {
            callback(output);
          }
        }
      },
      city // qui ci andrebbe la città per la ricerca ma come è possibile estrarla?
    );
  }

  /**
   * Return true if MarkerCluster is setted, otherwise false
   * @private
   * @returns boolean
  */
  private hasMarkerCluster(): boolean {
    return !!this._otm?.options?.mcOptions;
  }
}
