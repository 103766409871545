import { GeoJsonObject, Feature } from 'geojson';
import { Map, FeatureGroup, Layer } from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { ProviderParams, SearchResult } from 'leaflet-geosearch/dist/providers/provider';
import { ProviderClass } from '../interfaces/ProviderClass';
import { FeaturesGeoJson, ResponseGeneral, ResultsGeoJson } from '../interfaces/Responses';
import OnTheMap from './onthemap';
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
import { RawResult } from 'leaflet-geosearch/dist/providers/bingProvider';
import ResultList from 'leaflet-geosearch/dist/resultList';
import { StyleProcessResults } from '../interfaces/OnTheMap';

/**
 * @access private
 */
export default class OsmMap implements ProviderClass {
  /**
   * openstreetmap created
   * * @type {Map}
   */
  private _osmMap: Map;
  /**
   * Options with properties used in functionality
   * For details see {@link OnTheMap} class
   */
  private _otm: OnTheMap;

  /**
   * custom event for trigger infoWindowDomReady
   */
  private _infoDomReadyEvent: CustomEvent;

  /**
   * Marker attualmente nello stato "active"
   */
  private currentActiveMarker: L.Marker;

  constructor(otm: OnTheMap) {
    this._otm = otm;
  }

  /**
   * METHOD NOT IMPLEMENTED IN OPENSTREETMAP
   * @access private
   */
  distanceMatrixSearch(data: ResponseGeneral, originPosition: google.maps.LatLngLiteral, callback?: Function): void {
    throw new Error('Method not implemented.');
  }

  /**
   * setup the openstreetmap map
   * @access private
   * @param {Map} map
   * @param {boolean} hideOnTheMapLogo
   */
  setupMap(map: L.Map, hideOnTheMapLogo: boolean): void {
    let attribution: string;

    if(!hideOnTheMapLogo) {
      attribution = '<a href="https://onthemap.io/" target="_blank">OnTheMap</a> | &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors';
    } else {
      attribution = '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors';
    }

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: attribution
    }).addTo(map);

    this._osmMap = map;
  }

  /**
   * Add data layer to Openstreetmap map and add onclick if windowProcess is setted
   * @access private
   * @param {ResultsGeoJson} data: ResultsGeoJson data
   * @param {boolean} clear: if clear or not the old data on map
   * @param {boolean} disableFocus: if true disable focus on search results
   */
  addData(data: ResultsGeoJson | GeoJsonObject, clear: boolean = true, disableFocus: boolean = false): void {
    const features: FeaturesGeoJson[] = ((data as ResultsGeoJson).features as FeaturesGeoJson[]) || [];
    if (clear) {
      this.clearMap();
    }

    // Check coincident points - Start
    let checkCoincidentPoints = {};
    let coincidentPoints = {};

    features.forEach(function(feature) {
      const position: L.LatLng = L.latLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
      const tmp_key = position.lat + ',' + position.lng;

      if(!checkCoincidentPoints[tmp_key]) {
        checkCoincidentPoints[tmp_key] = {};
      }

      // Object.assign per creare una copia di "feature" e non inserire l'oggetto "coincidentPoint" in loop dentro "coincidentPoint"
      checkCoincidentPoints[tmp_key][feature["properties"]["otm_id"]] = Object.assign({}, feature);
    });

    for(let p = 0; p < Object.keys(checkCoincidentPoints).length; p++) {
      if(Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]).length > 1) {
        // console.log('Coincident points');
        // console.log(Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]));

        for(let a = 0; a < Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]).length; a++) {
          let key = Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]])[a];
          let cpObj = checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]];

          coincidentPoints[key] = cpObj;
        }
      } else {
        // console.log('Single point');
        // console.log(Object.keys(checkCoincidentPoints[Object.keys(checkCoincidentPoints)[p]]));
      }
    }

    // console.log('coincidentPoints');
    // console.log(coincidentPoints);

    features.forEach(function(feature) {
      if(feature["properties"]["otm_id"] in coincidentPoints) {
        // feature["coincidentPoint"] contiene un oggetto con:
        // - chiave: otm_id
        // - valore: feature (geometry, properties, type)
        // in cui sono presenti tutti i punti coincidenti con quello corrente (compreso il punto stesso)
        feature["coincidentPoint"] = coincidentPoints[feature["properties"]["otm_id"]];
      } else {
        feature["coincidentPoint"] = null;
      }
    });
    // Check coincident points - End

    // create index loop
    let index: number = 0;
    const geoJsonLayer: FeatureGroup = L.geoJSON(data as GeoJsonObject, {
      pointToLayer: (geoJsonPoint: GeoJSON.Feature, position: L.LatLng) => {
        // add custom icon only if exist styleProcess
        if (this._otm?.options?.styleProcess) {
          // set default state
          const imgObj: StyleProcessResults = this._otm.options.styleProcess(geoJsonPoint, index);

          // check if default is setted
          if (imgObj.default) {
            const marker: L.Marker & { otm_id?: string; feature?: Feature } = Object.assign(
              new L.Marker(position, imgObj.default as L.MarkerOptions),
              {
                otm_id: geoJsonPoint.properties?.otm_id || undefined,
                feature: geoJsonPoint,
              }
            );

            // Set marker "hover" state (if it exists)
            if(imgObj.hover) {
              marker.on('mouseover', () => {
                if(marker['otm_currentIconStyle'] !== 'active') {
                  marker.setIcon(imgObj.hover as L.Icon);

                  // Add current style information to marker
                  marker['otm_currentIconStyle'] = 'hover';
                }
              });

              marker.on('mouseout', () => {
                if(marker['otm_currentIconStyle'] !== 'active') {
                  marker.setIcon((imgObj.default as L.MarkerOptions).icon as L.Icon);

                  // Add current style information to marker
                  marker['otm_currentIconStyle'] = 'default';
                }
              });
            }

            // Add marker overlay to current result as "markerOverlay" (with "geometry" and "properties")
            geoJsonPoint['markerOverlay'] = marker;

            return marker;
          }
          // no markerOptions default setted in styleProcess
          else {
            console.error('OpenStreetMap addData: Default marker is not setted');
            return;
          }
        }
      },
      onEachFeature: (feature: GeoJSON.Feature, layer: L.Layer & { feature: GeoJSON.Feature }) => {
        if(this._otm.options.autoOpenInfoWindow) {
          const content: string = this._otm?.options?.infoWindowProcess(feature) || '';

          index++; // add index

          layer.bindPopup(content).on('click', () => {
            /** dispatch the infoWindowDomReady event */
            this._infoDomReadyEvent = new CustomEvent('osm.infoWindowDomReady', { detail: feature });
            window.dispatchEvent(this._infoDomReadyEvent);

            // set infoWindowActive
            (this._otm.infoWindowActive as L.Popup) = layer.getPopup();

            // set active state if exist
            const imgObj: StyleProcessResults = this._otm.options.styleProcess(layer.feature as GeoJSON.Feature, index);

            if(imgObj.active) {
              (layer as L.Marker).setIcon(imgObj.active as L.Icon);

              // Add current style information to marker
              (layer as L.Marker)['otm_currentIconStyle'] = 'active';

              layer.on('popupclose', (event) => {
                (layer as L.Marker).setIcon((imgObj.default as L.MarkerOptions).icon as L.Icon);

                // Add current style information to marker
                (layer as L.Marker)['otm_currentIconStyle'] = 'default';

                (this._otm.infoWindowActive as L.Popup) = undefined;
              });
            }
          });
        } else {
          index++; // add index

          layer.on('click', () => {
            const imgObj: StyleProcessResults = this._otm.options.styleProcess(layer.feature as GeoJSON.Feature, index);

            // Reset "default" status of the previous selected marker
            if(!this.currentActiveMarker) {
              this.currentActiveMarker = (layer as L.Marker);
            } else {
              this.currentActiveMarker.setIcon((imgObj.default as L.MarkerOptions).icon as L.Icon);

              // Add current style information to marker
              this.currentActiveMarker['otm_currentIconStyle'] = 'default';

              // Update the current active marker
              this.currentActiveMarker = (layer as L.Marker);
            }

            if(imgObj.active) {
              (layer as L.Marker).setIcon(imgObj.active as L.Icon);

              // Add current style information to marker
              (layer as L.Marker)['otm_currentIconStyle'] = 'active';
            }
          });
        }
      },
    }).addTo(this._osmMap);

    (this._otm.featureCollection as FeatureGroup) = geoJsonLayer;
  }

  /**
   * Remove data from openstreetmap map
   * @access private
   */
  clearMap(): void {
    if (this._otm.featureCollection instanceof Array) {
      this._otm.featureCollection = [];
    } else {
      if ((this._otm.featureCollection as FeatureGroup).getLayers().length) {
        (this._otm.featureCollection as FeatureGroup).clearLayers();
      }
    }
  }

  /**
   * focus in the bounds of openstreetmap map
   * @access private
   * @param {FeatureGroup} featureCollection: the features collection
   */

  focus(featureCollection: FeatureGroup, search_position?): void {
    if (!!featureCollection.getLayers().length) {
      const bounds: L.LatLngBounds = L.latLngBounds(null, null);

      featureCollection.eachLayer((layer) => {
        const feature = layer['feature'];
        const geom = feature['geometry']['coordinates'];

        bounds.extend(L.latLng(geom[1], geom[0]));
      });

      // estensione bounds all'indirizzo ricercato
      if(search_position && search_position.length == 2) {
        let search_position_latlng =  L.latLng(search_position[0], search_position[1]);
        bounds.extend(search_position_latlng);
      }

      this._osmMap.fitBounds(bounds);
    }
  }

  /**
   * It open an infoWindow for a specific id and center the map on it
   * @access private
   * @param {string} otm_id: otm_id of a feature to open in infoWindow
   * @param {FeatureGroup} featureCollection: collections of features result of the last search
   */
  openInfoWindow(otm_id: string, featureCollection: FeatureGroup): void {
    featureCollection.eachLayer((layer: Layer) => {
      const layerFeat = layer['feature'];
      const id = layerFeat.properties?.otm_id;

      if (id === otm_id) {
        const position: L.LatLng = L.latLng(layerFeat.geometry.coordinates[1], layerFeat.geometry.coordinates[0]);
        if (this._otm.options.autoOpenInfoWindow) {
          layer.openPopup(position);
          (this._otm.infoWindowActive as L.Popup) = layer.getPopup();

          const imgObj: StyleProcessResults = this._otm.options.styleProcess(layerFeat as GeoJSON.Feature);

          if(imgObj.active) {
            (layer as L.Marker).setIcon(imgObj.active as L.Icon);

            // Add current style information to marker
            (layer as L.Marker)['otm_currentIconStyle'] = 'active';

            layer.on('popupclose', (event) => {
              (layer as L.Marker).setIcon((imgObj.default as L.MarkerOptions).icon as L.Icon);

              // Add current style information to marker
              (layer as L.Marker)['otm_currentIconStyle'] = 'default';

              (this._otm.infoWindowActive as L.Popup) = undefined;
            });
          }

          setTimeout(() => {
            this._osmMap.panTo(position);
          }, 200);
        }

        // console.error("'openInfoWindow' not yet implemented");
        return;
      }
    });
  }

  /**
   * Create the autocomplete element and return the address object to callback
   * @access private
   * @param {HTMLInputElement} container - input element html container the autocomplete functionality
   * @param {ProviderParams} options - options for autocomplete element: see {@link ProviderParams}
   * @param {Function} callback - function called
   */

  setAutocomplete(container: HTMLInputElement, options: ProviderParams, callback: Function): void {
    const autocomplete = new OpenStreetMapProvider({
      params: options as ProviderParams,
    });

    var timeout;
    var bodyRect = document.body.getBoundingClientRect();
    var elemRect = container.getBoundingClientRect();
    var top = elemRect.top - bodyRect.top + container.offsetHeight + 10 + 'px';
    var left = elemRect.left - bodyRect.left + 7 + 'px';

    var maxRes = 5;

    var css = [];

    var containerStyle =
      'border-radius: 2px; border-top: 1px solid #d9d9d9; font-family: Arial,sans-serif; box-shadow: 0 2px 6px rgb(0 0 0 / 30%);';
    containerStyle += ' -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; overflow: hidden;';
    containerStyle +=
      'position: absolute; top: ' + top + '; left: ' + left + '; z-index: 10000; background: #fff; width: ' + container.offsetWidth + 'px;';
    css.push('.otm-autocomplete-container { ' + containerStyle + ' }');

    let itemStyle = 'padding: 5px; margin: 5px 0; border-bottom: 1px solid #eee; font-size: 14px; height: 20px;';
    itemStyle += 'white-space: nowrap; line-height: 20px; text-overflow: ellipsis; overflow: hidden; cursor: pointer;';
    css.push('.otm-autocomplete-item { ' + itemStyle + ' }');
    css.push('.otm-autocomplete-item:hover { background: #efefef;  }');

    const autocompleteStyleElement: HTMLStyleElement = document.createElement('style');
    autocompleteStyleElement.type = 'text/css';
    autocompleteStyleElement.appendChild(document.createTextNode(css.join('')));

    document.getElementsByTagName('head')[0].appendChild(autocompleteStyleElement);

    window['_otm_autocomplete_onChange'] = (lat, lng, label) => {
      container.value = label.replace("\\'", "'");
      removeAutocompleteBox();

      if (callback && typeof callback == 'function') {
        callback({
          lat: lat,
          lng: lng,
          value: label,
        });
      }
    };

    const removeAutocompleteBox = () => {
      var autocompleteNode = document.getElementById('otm-auto');
      if (autocompleteNode) {
        autocompleteNode.remove();
      }
    };

    const addAutocompleteBox = (value: string) => {
      // @ts-ignore
      autocomplete.search({ query: value }).then((result) => {
        const html = [];
        html.push('<div class="otm-autocomplete-container">');

        let count = 0;
        for (var i = 0; i < result.length; i++) {
          // ritorna solamente gli indirizzi
          if (result[i]['raw']['type'] != 'administrative') {
            break;
          }

          count++;

          // evidenzio la keyword ricercata
          let label = result[i]['label'];
          const reg = new RegExp('(' + value + ')', 'gi');
          label = label.replace(reg, '<b>$1</b>');

          if (i === result.length - 1 || count === maxRes) {
            html.push(
              '<div onclick="_otm_autocomplete_onChange(' +
                result[i]['y'] +
                ', ' +
                result[i]['x'] +
                ", '" +
                result[i]['label'].replace("'", "\\'") +
                '\')" class="otm-autocomplete-item" style="border-bottom: 0;">' +
                label +
                '</div>'
            );
          } else {
            html.push(
              '<div onclick="_otm_autocomplete_onChange(' +
                result[i]['y'] +
                ', ' +
                result[i]['x'] +
                ", '" +
                result[i]['label'].replace("'", "\\'") +
                '\')" class="otm-autocomplete-item">' +
                label +
                '</div>'
            );
          }
        }

        html.push('</div>');

        // rimuove eventuali autocomplete precedenti
        removeAutocompleteBox();

        // crea nuovo autocomplete
        const child = document.createElement('div');
        child.id = 'otm-auto';
        child.innerHTML = html.join('');
        document.body.appendChild(child);
      });
    };

    // rimuovo autocomplete alla perdita del focus
    container.addEventListener('blur', (event: FocusEvent) => {
      setTimeout(function () {
        removeAutocompleteBox();
      }, 200);
    });

    // apro autocomplete al focus
    container.addEventListener('focus', (event: FocusEvent) => {
      addAutocompleteBox(container.value);
    });

    // keyup
    container.addEventListener('keyup', (event: KeyboardEvent) => {
      const el: HTMLInputElement = event.target as HTMLInputElement;
      if (timeout) {
        clearTimeout(timeout);
      }

      if (event.isComposing || event.key.charCodeAt(229)) {
        return;
      }

      if (el.value && el.value.length > 3) {
        timeout = setTimeout(function () {
          addAutocompleteBox(el.value);
        }, 250);
      } else {
        removeAutocompleteBox();
      }
    });
  }

  /**
   * geocode an address @todo geocode the address
   * @access private
   * @param {string} value: address string
   * @param {ProviderParams} options: params for openstreetmap provider
   * @param {Function} callback: callback function
   */
  geocode(value: string, options?: ProviderParams, callback?: Function): void {
    const geocoder = new OpenStreetMapProvider({
      params: options,
    });

    let output: SearchResult<RawResult>[] = new Array();

    geocoder.search({ query: value }).then((result: SearchResult<any>[]) => {
      if (result?.length) {
        output = result;
      } else {
        console.error('Address not found');
      }

      if (callback && typeof callback == 'function') {
        callback(output);
      }
    });
  }
}
